import React from "react";
import { Breadcrumb, Button, Col, Layout, Modal, Row, Tag, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoDocument, IoDocumentText, IoPencil, IoReturnUpBackOutline, IoSwapVertical, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, getEnv, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
    }

    componentDidMount = () => {
        titleWeb('Documentos Fiscais')
    }
    
    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item>Documentos Fiscais</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        </Col>
                        <Col span={4}>
                            <Link to="nfe">
                                <div className="doc-card">
                                    <IoDocumentText className="doc-icon" />
                                    <Typography className="doc-text">NFe</Typography>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;