import React from "react";
import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Layout, Row, Tooltip, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, getEnv, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import conf from './conf.json';
import { IoInformationCircle } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: {
            'Menu': [],
            'Ambientes': [],
            'Permissões': [],
            'Tipos de Usuário': [],
            'Clientes': [],
            'Empresas': [],
            'Planos': [],
            'Usuários': [],
            'Fornecedores': [],
            'Categorias': [],
            'Marcas': [],
            'Produtos': [],
            'Compras': [],
            'Contas a Pagar': [],
            'Contas a Receber': [],
            'Despesas': [],
            'Formas de Pagamento': [],
            'Vendas': [],
        },
        menu: [],
        perm: [],
    }

    componentDidMount = () => {
        titleWeb('Tipos de Usuário')
        this.onLoadPermission()
        if (this.props.type === 'add') {
            this.onPermission()
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onChangeField = (values) => {

        var temp = this.state.defaultValue
        var label = values.target['data-group'];
        var val = values.target.value

        if (temp?.[label].includes(val)) {
            temp?.[label].splice(temp?.[label].indexOf(val), 1)
        } else {
            temp?.[label].push(val)
        }
        
        this.setState({ defaultValue: temp })
    } 

    onChangeAll = (values) => {

        var temp = this.state.defaultValue
        var label = values.target.id;

        if (values.target['aria-checked'] === 'mixed') {
            temp[label] = []
        } else if (values.target.checked) {
            if (label === 'Menu') {
                this.state.menu.map(v => {
                    temp[label].push(v.ID)
                    return null
                })
            } else {
                this.state.perm[label].map(v => {
                    temp[label].push(v.ID)
                    return null
                })
            }
        } else {
            temp[label] = []
        }
        
        this.setState({defaultValue: temp})

    }

    onLoadPermission = () => {
        var control = (this.state._params[7] ) ? this.state._params[5] : 1
        POST_API('/config/load.php', { token: getToken(), ACCOUNT_ID: control }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({menu: res.menu, perm: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onView = async () => {
        var control = { ID: (this.state._params[7] ) ? this.state._params[7] : this.state._params[5] }
        if (this.state._params[7] ) control.ACCOUNT_ID = this.state._params[5]
        POST_API('/typecredential/search.php', { token: getToken(), type: 'view', filter: JSON.stringify(control) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onPermission = async () => {
        var control = (this.state._params[7] ) ? this.state._params[5] : 1
        POST_API('/config/groups.php', { token: getToken(), ACCOUNT_ID: control }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, _screen: false})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        if (this.state._params[7]) values.ACCOUNT_ID = this.state._params[5];
        
        POST_API('/typecredential/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify({...values, ...this.state.defaultValue, NAME: values.NAME}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate) {
            if (this.state._params[7] ) {
                return (<Navigate to={`/dashboard/account/${this.state._params[5]}/typecredential`} />)
            } else {
                return (<Navigate to={'/dashboard/typecredential'} />)
            }
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                { this.state._params[7]  ? (
                                    <Breadcrumb.Item><Link to="/dashboard/account">Clientes</Link></Breadcrumb.Item>
                                ) : null }
                                <Breadcrumb.Item><Link to={ this.state._params[7] ? `/dashboard/account/${this.state._params[5]}/typecredential` : "/dashboard/typecredential" }>Tipos de Usuário</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small" onClick={() => this.setState({_navigate: true})}>Voltar</Button>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`${getEnv()}-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,8]}>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Card hoverable title='Módulos'>
                                                <Form.Item name="Menu">
                                                    <Row>
                                                        <Col span={24}>
                                                            <Checkbox onChange={this.onChangeAll} id="Menu" indeterminate={(!!this.state.defaultValue.Menu.length && this.state.defaultValue.Menu.length < this.state.menu.length)} checked={(this.state.defaultValue.Menu.length === this.state.menu.length)} >
                                                                Selecionar Todos
                                                            </Checkbox>
                                                        </Col>
                                                        <Col span={24}>
                                                            {this.state.menu.map((item, index) =>(
                                                                <Col xs={24} md={8} key={index} style={{ display: 'flex', alignItems: 'center'}}>
                                                                    <Tooltip placement="right" title={item.KEY}><IoInformationCircle size={18} style={{marginRight: 5}} /></Tooltip><Checkbox data-group="Menu" onChange={this.onChangeField} value={item.ID} checked={this.state.defaultValue?.['Menu']?.includes(item.ID)} >{item.KEY}</Checkbox>
                                                                </Col>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                            </Card>
                                        </Col>
                                        {Object.keys(this.state.perm).map((v, i) =>(
                                            <Col xs={24} md={8} key={i}>
                                                <Card hoverable title={v}>
                                                    <Form.Item name={v}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Checkbox onChange={this.onChangeAll} id={v} indeterminate={(!!this.state.defaultValue?.[v].length && this.state.defaultValue?.[v].length < this.state.perm[v].length)} checked={(this.state.defaultValue?.[v].length === this.state.perm[v].length)} >
                                                                    Selecionar Todos
                                                                </Checkbox>
                                                            </Col>
                                                            {this.state.perm[v].map((item, index) =>(
                                                                <Col span={24} key={index} style={{ display: 'flex', alignItems: 'center'}}>
                                                                    <Tooltip placement="right" title={item.KEY}><IoInformationCircle size={18} style={{marginRight: 5}} /></Tooltip><Checkbox data-group={v} onChange={this.onChangeField} value={item.ID} checked={this.state.defaultValue?.[v].includes(item.ID)} >{item.KEY}</Checkbox>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Form.Item>
                                                </Card>
                                            </Col>
                                        ))}
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;