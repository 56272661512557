import React from "react"
import { Button, Col, Form, Image, Input, Layout, Row, message } from "antd"

import { ACCOUNT_ID, POST_API, getEnv, getToken, setConfig, setToken, titleWeb } from "../../services/api"
import { Navigate } from "react-router-dom"

class Screen extends React.Component {

    state = {
        _loading: false,
        _navigate: false,
    }

    componentDidMount = () => {
        titleWeb('Login')
        const favicon = document.getElementById("favicon");
        favicon.setAttribute("href", 'https://storenew.adsolucoestecnologia.com.br/services/_upload/1/account/6852023.04.28-13.23.11.png'); 
        document.querySelector(':root').style.setProperty('--primary', `#376cff`);
        document.querySelector(':root').style.setProperty('--hover', `#032fa7`);
        if (getToken()) {
            message.loading({ content: 'Carregando...', key: 'login' })
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({_navigate: true})
                } else {
                    message.warning({ content: res.msg, key: 'login' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'login' })
            }).finally(() => {
                this.setState({_loading: false})
            })
        }
        this.setState({_loading: false})
    }

    onSend = (values) => {

        values.ACCOUNT_ID = ACCOUNT_ID

        this.setState({_loading: true})
        message.loading({ content: 'Carregando...', key: 'login' })

        POST_API('/credential/login.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setToken(res.token)
                setConfig(JSON.stringify(res.config))
                message.success({ content: res.msg, key: 'login' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'login' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'login' })
        }).finally(() => {
            this.setState({_loading: false})
        })

    }

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="dashboard" />
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-login-background`}>
                    <Row justify='center' align='middle' style={{height: '100%'}}>
                        <Col md={8} xs={20} className={`${getEnv()}-login-content`}>
                            <Image style={{marginBottom: 10}} src={'https://storenew.adsolucoestecnologia.com.br/services/_upload/1/account/6852023.04.28-13.23.11.png'} preview={false} width='60%' />
                            <Form layout="vertical" onFinish={this.onSend}>
                                <Form.Item name="LOGIN" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                    <Input placeholder="Digite seu usuário" label="Usuário" variant="outlined" />
                                </Form.Item>
                                <Form.Item name="PASSWORD" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                    <Input placeholder="Digite sua senha" type="password" label="Senha" variant="outlined" />
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="btn-primary" shape="round" block>Entrar</Button>
                                    <Button type="link" className="btn-link" block>Esqueceu a senha?</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }

}

export default Screen