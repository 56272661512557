import React from "react";
import { Breadcrumb, Button, Card, Col, Divider, Form, Image, Layout, Modal, Row, Typography, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, getEnv, getToken, getUPLOADAPI, setPath, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import conf from './conf.json';
import { IoTrash } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        photos: []
    }

    componentDidMount = async () => {
        titleWeb('Galeria')
        setPath('product')
        this.onView()
        this.setState({_screen: false})
    }

    onView = async () => {
        POST_API('/product_variation_photo/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({PRODUCT_VARIATION_ID: this.state._params[6]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({photos: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        values.PRODUCT_VARIATION_ID = this.state._params[6]
        POST_API('/product_variation_photo/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_screen: true})
                message.success({ content: res.msg, key: 'screen' })
                this.onView()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/product_variation_photo/del.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onView()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/product'} />)
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/product">Produtos</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Variação</Breadcrumb.Item>
                                <Breadcrumb.Item>Galeria</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={'/dashboard/product'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        <Col span={24} className={`${getEnv()}-form`}>
                            <Row gutter={[8, 0]} style={{paddingLeft: 20, paddingRight: 20}}>
                                <Col span={24}><Divider>Galeria</Divider></Col>
                                { this.state.photos.length > 0 ? (
                                    <>
                                        {this.state.photos.map((v, i) => (
                                            <Col md={6} xs={24} key={i}>
                                                <Card hoverable actions={[<Row justify={'center'}><Button disabled={!verifyConfig(conf.del)} onClick={() => this.onAction(v.ID, 'del', 'Excluir foto?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Row>]}>
                                                    <Image src={v.URL} />
                                                </Card>
                                            </Col>
                                        ))}
                                    </>
                                ) : <Typography>Sem fotos</Typography>}
                                <Col span={24}><Divider>Adicionar Fotos</Divider></Col>
                                <Col span={24}>
                                    { this.state._screen ? <PageLoad /> : (
                                        <Form layout="vertical"  onFinish={this.onSend}>
                                            <Form.Item label="Imagens" name="IMAGENS" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Upload
                                                    accept="image/png,image/jpg,image/jpeg"
                                                    action={getUPLOADAPI}
                                                    listType="picture-card"
                                                >
                                                    <div>
                                                        <div style={{ marginTop: 8 }}>Selecionar</div>
                                                    </div>
                                                </Upload>
                                            </Form.Item>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round">Enviar Fotos</Button>
                                        </Form>
                                    ) }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;