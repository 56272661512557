import conf from '../assets/json/config.json';

var PATH = '';
var LOGO_LOGIN = '';
var LOGO_NAVBAR = '';
var LOGO_SIDEBAR = '';
var FOOTER = '';
var COLOR = '#7692af';
var TITLE = 'AD Store';

export const setPath = (value) => { PATH = value }
export const getPath = () => { return PATH }
export const setTitle = (value) => { TITLE = value }
export const getTitle = () => { return TITLE }
export const setLogoLogin = (value) => { LOGO_LOGIN = value }
export const getLogoLogin = () => { return LOGO_LOGIN }
export const setLogoNavbar = (value) => { LOGO_NAVBAR = value }
export const getLogoNavbar = () => { return LOGO_NAVBAR }
export const setLogoSidebar = (value) => { LOGO_SIDEBAR = value }
export const getLogoSidebar = () => { return LOGO_SIDEBAR }
export const setFooter = (value) => { FOOTER = value }
export const getFooter = () => { return FOOTER }
export const setColor = (value) => { COLOR = value }
export const getColor = () => { return COLOR }

export const ACCOUNT_ID = conf.accountID;
export const URL_API = conf.url+'/services/php';
export const UPLOAD_API = conf.url+'/services/php/upload/upload.php';

export const BLOCK_FORM_ENTER = (e) => {
    if (e.key === "Enter") {
        e.preventDefault();
    }
}

export const getUPLOADAPI = () => {
    return UPLOAD_API+'?path='+getPath()+'&token='+getToken();
}

export const titleWeb = (value) => {
    window.document.title = `${getTitle()} | ${value}`
}

export const setEnv = (value) => {
    localStorage.setItem('ENV', value);
}

export const getEnv = () => {
    return localStorage.getItem('ENV');
}

export const setToken = (value) => {
    localStorage.setItem('TOKEN', value);
}

export const getToken = () => {
    return localStorage.getItem('TOKEN');
}

export const delToken = () => {
    localStorage.removeItem('TOKEN')
}

export const setConfig = (value) => {
    localStorage.setItem('CONFIG', value);
}

export const getConfig = () => {
    return localStorage.getItem('CONFIG');
}

export const delConfig = () => {
    localStorage.removeItem('CONFIG')
}

export const verifyConfig = (value) => {
    var config = JSON.parse(getConfig())
    return (config[value] === 'T')
}

export const POST_API = (url, data) => {

    function createFormData () {
        const form = new FormData()
    
        Object.keys(data).forEach(key => {
            form.append(key, data[key]);
        });
      
        return form;
    };
  
    return fetch(URL_API+url, { method: "post", body: createFormData() })

}
