import React from "react";
import { Breadcrumb, Button, Col, Divider, Input, Layout, Modal, Row, Select, Tag, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoPencil, IoReturnUpBackOutline, IoSend, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, getEnv, getToken, titleWeb } from "../../../../services/api";

// COMPONENTS
import Table from '../../../../components/Table';

// CONTROLLER
import Page403 from '../../../Controller/Page403';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [ ],
        table_search: '',
        table_pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        table_filter: null,
        table_sorter: { columnKey: 'ID', order: 'descend' },
        table_select: [],
        _dados: null,
        valueFilters: {
            FOCUSNFE_CONF_ID: '',
            STATUS: '',
            TIPO_DOCUMENTO: '',
            LOCAL_DESTINO: '',
            FINALIDADE_EMISSAO: '',
            CONSUMIDOR_FINAL: '',
            PRESENCA_COMPRADOR: '',
            DATA_CADASTRO_START: '',
            DATA_CADASTRO_END: '',
            DATA_EMISSAO_START: '',
            DATA_EMISSAO_END: '',
            DATA_ENTRADA_SAIDA_START: '',
            DATA_ENTRADA_SAIDA_END: '',
        }
    }

    componentDidMount = async () => {
        titleWeb('NFe')
        await POST_API('/nfe/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({_dados: res.data})
            this.onRenderColumns().then(() => this.onRenderData())
        })
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/nfe/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/nfe/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onEmitirNota = (ID) => {
        var self = this
        Modal.confirm({
            title: 'Deseja emitir a nota?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/nfe/emitir.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                    self.onRenderData()
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        
        this.setState({
            table_column: [
                { title: 'Cod.', fixed: 'left', dataIndex: 'ID', key: 'focusnfe_nfe.ID', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false , render: (text, record) => (
                    <Tag color={ record.USAR === 'H' ? '#FF9900' : '#33CCFF' }>{ record.ID }</Tag>
                ) },
                { title: 'Situação', width: 300, align: 'left', dataIndex: 'STATUS', key: 'focusnfe_nfe.STATUS', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <>
                        <Tag>{record.STATUS}</Tag>
                        <Typography>{record.MENSAGEM_SEFAZ}</Typography>
                    </>
                ) },
                { title: 'Data Emissão', dataIndex: 'DATA_EMISSAO', key: 'focusnfe_nfe.DATA_EMISSAO', width: 150, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Destinatário', dataIndex: 'NOME_DESTINATARIO', key: 'focusnfe_nfe.NOME_DESTINATARIO', align: 'left', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <>
                        <Typography><b>Natureza Operação:</b> {record.NATUREZA_OPERACAO}</Typography>
                        <Typography><b>{record.CNPJ_DESTINATARIO ? 'Razão Social' : 'Nome'}:</b> {record.NOME_DESTINATARIO}</Typography>
                        <Typography><b>{record.CNPJ_DESTINATARIO ? 'CNPJ' : 'CPF'}:</b> {record.CNPJ_DESTINATARIO ? record.CNPJ_DESTINATARIO : record.CPF_DESTINATARIO}</Typography>
                    </>
                ) },
                { title: 'Ações', fixed: 'right', key: 'action', width: 180, render: (text, record) => (
                    <>
                        <Row gutter={[4,4]} className="table__actions">
                            <Col><Link to={record.ID}><Button className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col>
                            <Col><Button onClick={() => this.onAction(record.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                            <Col><Divider type="vertical"/></Col>
                            <Col><Button onClick={() => this.onEmitirNota(record.ID)} className="table__btn table__btn_recover" title='Emitir Nota' type="primary" shape="circle" size="small"><IoSend size={14} /></Button></Col>
                        </Row>
                    </>
                )},
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/fiscal">Documentos Fiscais</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to="/dashboard/fiscal/nfe">NFe</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{ this.props.type === 'list' ? 'Lista' : 'Lixeira' }</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button type="default" onClick={this.onRenderData} size="small" shape="round" className="sys-btn-default sys-btn-small" style={{marginRight: 6}}>Filtrar</Button>
                            <Link to={'add'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link>
                        </Col>
                        <Col span={24}>
                            <Row className="table__row_table" gutter={[8,8]} style={{marginBottom: 15}}>
                                <Col md={19} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Empresa Emitente</Typography>
                                    <Select style={{width: '100%'}} allowClear={true} value={this.state.valueFilters.FOCUSNFE_CONF_ID} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, FOCUSNFE_CONF_ID: v } })}>
                                        <Select.Option disabled>Selecione...</Select.Option>
                                        { this.state._dados?.FOCUSNFE_CONF_ID.map((v, i) => (
                                            <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                        )) }
                                    </Select>
                                </Col>
                                <Col md={5} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Situação</Typography>
                                    <Select style={{width: '100%'}} allowClear={true} value={this.state.valueFilters.STATUS} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, STATUS: v } })}>
                                        <Select.Option disabled>Selecione...</Select.Option>
                                        { this.state._dados?.STATUS.map((v, i) => (
                                            <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                        )) }
                                    </Select>
                                </Col>
                                <Col md={4} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Tipo de Documento</Typography>
                                    <Select style={{width: '100%'}} allowClear={true} value={this.state.valueFilters.TIPO_DOCUMENTO} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, TIPO_DOCUMENTO: v } })}>
                                        <Select.Option disabled>Selecione...</Select.Option>
                                        { this.state._dados?.TIPO_DOCUMENTO.map((v, i) => (
                                            <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                        )) }
                                    </Select>
                                </Col>
                                <Col md={5} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Local de Destino</Typography>
                                    <Select style={{width: '100%'}} allowClear={true} value={this.state.valueFilters.LOCAL_DESTINO} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, LOCAL_DESTINO: v } })}>
                                        <Select.Option disabled>Selecione...</Select.Option>
                                        { this.state._dados?.LOCAL_DESTINO.map((v, i) => (
                                            <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                        )) }
                                    </Select>
                                </Col>
                                <Col md={4} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Finalidade de Emissão</Typography>
                                    <Select style={{width: '100%'}} allowClear={true} value={this.state.valueFilters.FINALIDADE_EMISSAO} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, FINALIDADE_EMISSAO: v } })}>
                                        <Select.Option disabled>Selecione...</Select.Option>
                                        { this.state._dados?.FINALIDADE_EMISSAO.map((v, i) => (
                                            <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                        )) }
                                    </Select>
                                </Col>
                                <Col md={4} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Consumidor Final</Typography>
                                    <Select style={{width: '100%'}} allowClear={true} value={this.state.valueFilters.CONSUMIDOR_FINAL} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, CONSUMIDOR_FINAL: v } })}>
                                        <Select.Option disabled>Selecione...</Select.Option>
                                        { this.state._dados?.CONSUMIDOR_FINAL.map((v, i) => (
                                            <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                        )) }
                                    </Select>
                                </Col>
                                <Col md={7} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Presença do Comprador</Typography>
                                    <Select style={{width: '100%'}} allowClear={true} value={this.state.valueFilters.PRESENCA_COMPRADOR} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, PRESENCA_COMPRADOR: v } })}>
                                        <Select.Option disabled>Selecione...</Select.Option>
                                        { this.state._dados?.PRESENCA_COMPRADOR.map((v, i) => (
                                            <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                        )) }
                                    </Select>
                                </Col>
                                <Col md={4} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Cadastro (Ínicio)</Typography>
                                    <Input type="date" value={this.state.valueFilters.DATA_CADASTRO_START} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, DATA_CADASTRO_START: v.target.value } })} />
                                </Col>
                                <Col md={4} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Cadastro (Final)</Typography>
                                    <Input type="date" value={this.state.valueFilters.DATA_CADASTRO_END} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, DATA_CADASTRO_END: v.target.value } })} />
                                </Col>
                                <Col md={4} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Emissão (Ínicio)</Typography>
                                    <Input type="date" value={this.state.valueFilters.DATA_EMISSAO_START} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, DATA_EMISSAO_START: v.target.value } })} />
                                </Col>
                                <Col md={4} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Emissão (Final)</Typography>
                                    <Input type="date" value={this.state.valueFilters.DATA_EMISSAO_END} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, DATA_EMISSAO_END: v.target.value } })} />
                                </Col>
                                <Col md={4} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Entrada/Saída (Ínicio)</Typography>
                                    <Input type="date" value={this.state.valueFilters.DATA_ENTRADA_SAIDA_START} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, DATA_ENTRADA_SAIDA_START: v.target.value } })} />
                                </Col>
                                <Col md={4} xs={24}>
                                    <Typography style={{fontWeight: 600}}>Entrada/Saída (Final)</Typography>
                                    <Input type="date" value={this.state.valueFilters.DATA_ENTRADA_SAIDA_END} onChange={(v) => this.setState({ valueFilters: { ...this.state.valueFilters, DATA_ENTRADA_SAIDA_END: v.target.value } })} />
                                </Col>
                            </Row>
                            <Table
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;