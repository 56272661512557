import { Button, Card, Carousel, Checkbox, Col, Form, Image, Input, InputNumber, Layout, List, Modal, Row, Select, Spin, Statistic, Typography, message } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";

// CONTROLLER
import Page403 from '../Controller/Page403';
import PageLoad from '../Controller/PageLoad';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { POST_API, URL_API, getEnv, getToken, titleWeb } from "../../services/api";
import { IoAddCircleOutline, IoBarcodeOutline, IoPencil, IoTrash } from "react-icons/io5";
import ReactInputMask from "react-input-mask";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _params: window.location.href.split('/'),
        checkout: null,
        modal: false,
        caixas: [],
        select: null,
        value: 0,
        filters: {
            FILTER_PAYMENT: []
        },
        valorbruto: 0,
        valorfinal: 0,
        valorcredito: '',
        valordesconto: '',
        valortroco: '',
        valorpago: '',
        valortaxa: false,
        aplicartaxa: false,
        formapagamento: null,
        cpf: '',
        // CAMPOS
        search: '',
        qtde: '',
        _search: false,
        _lista: [],
        selecionados: [],
        modalFechamento: false,
        totalfechamento: 0,
        // FECHAMENTO
        moeda5: 0,
        moeda10: 0,
        moeda25: 0,
        moeda50: 0,
        moeda1: 0,
        nota2: 0,
        nota5: 0,
        nota10: 0,
        nota20: 0,
        nota50: 0,
        nota100: 0,
        nota200: 0,
        // EDITAR PRECO
        modaledit: false,
        editIndex: -1,
        editValues: null
    }

    componentDidMount = async () => {
        titleWeb('Caixa')
        POST_API('/checkout/load.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (!res.return) {
                this.setState({modal: true, caixas: res.data})
            } else {
                this.setState({checkout: res.data, filters: res.filters})
            }
        })

    }

    openCheckout = () => {

        if (this.state.select) {

            POST_API('/checkout/open.php', { token: getToken(), ID: this.state.select, VALUE: this.state.value }).then(rs => rs.json()).then(res => {
                if (!res.return) {
                    message.error(res.msg)
                    this.setState({_navigate: true})
                } else {
                    message.success(res.msg)
                    this.setState({checkout: res.data, modal: false, filters: res.filters})
                }
            })

        } else {
            message.warning("Selecione um caixa")
        }

    }

    onSearch = async (v) => {
        this.setState({search: v.target.value})
        if (v.target.value === '') {
            this.setState({_lista: []})
        } else {
            this.onSearchLoad(v.target.value)
        }
    }

    onSearchLoad = async (value) => {
        this.setState({_search: true})
        POST_API('/product_variation/search.php', { token: getToken(), type: 'list', pagination: JSON.stringify({current: 0, limit: 10}), search: value }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_lista: res.data});
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_search: false}))
    }

    onSelect = (v) => {
        this.setState({_lista: [v], search: v.BARCODE});
    }

    onselect = async () => {
        if (Number(this.state.qtde) <= Number(this.state._lista[0].QTY_STOCK)) {

            if (this.state.qtde > 0) {
                var temp = this.state._lista[0]
                temp['QTDE'] = this.state.qtde
                temp['TOTAL'] = Number(this.state.qtde * temp.PRICE_SALE).toFixed(2)
                this.setState({
                    selecionados: [
                        ...this.state.selecionados,
                        temp
                    ],
                    qtde: '',
                    _lista: [],
                    search: ''
                })
            } else {
                message.warning('Quantidade deve ser maior que 0')
            }
        } else {
            message.warning('Estoque indisponível')
        }
    }

    addSelect = () => {
        this.onselect().then(() => this.onBlurQTY())
    }

    onRemoveProd = (v) => {
        var temp = this.state.selecionados;
        temp.splice(v, 1)
        this.setState({selecionados: temp})
        this.onBlurQTY()
    }

    onChangeQTY = (v, i) => {
        var temp = this.state.selecionados;
        temp[i].QTY = v.target.value
        this.setState({selecionados: temp})
    }

    onBlurQTY = () => {
        var value = 0;
        var final = 0;
        var temp = this.state.selecionados;
        temp.map((item) => {
            value += Number(item.TOTAL)
            return 1
        })

        var tax = (this.state.aplicartaxa) ? value*this.state.valortaxa/100 : 0
        var desconto = value*this.state.valordesconto/100
        final = value - this.state.valorcredito - desconto + tax;

        this.setState({valorbruto: value, valorfinal: final, _loading: false})
    }

    onChangeCPF = (v) => {
        this.setState({cpf: v.target.value})
    }

    onChangeFormaPagamento = (v) => {
        this.setState({formapagamento: v})
    }

    onChangeCredito = (v) => {
        this.setState({valorcredito: v.target.value})
    }

    onChangeDesconto = (v) => {
        this.setState({valordesconto: v.target.value})
    }

    onChangeTaxa = (v) => {
        this.setState({valortaxa: v})
    }

    changeAplicarTaxa = async (value) => {
        this.setState({aplicartaxa: value})
    }

    onChangeAplicarTaxa = (v) => {
        this.changeAplicarTaxa(v.target.checked).then(() => this.onBlurQTY())
    }

    onBlurPago = (v) => {
        this.setState({valortroco: Number(v.target.value - this.state.valorfinal).toFixed(2)})
    }

    onChangePago = (v) => {
        this.setState({valorpago: v.target.value})
    }

    onChangeTroco = (v) => {
        this.setState({valortroco: v.target.value})
    }

    onCancelSale = () => {
        this.setState({
            valorbruto: 0,
            valorfinal: 0,
            valorcredito: '',
            valordesconto: '',
            valortroco: '',
            valorpago: '',
            valortaxa: false,
            aplicartaxa: false,
            formapagamento: null,
            cpf: '',
            // CAMPOS
            search: '',
            qtde: '',
            _search: false,
            _lista: [],
            selecionados: [],
            
        })
    }

    onEditProd = (values, index) => {
        this.setState({modaledit: true, editValues: values, editIndex: index})
    }

    onSend = () => {
        
        if (this.state.selecionados.length > 0) {
            if (this.state.formapagamento > 0) {
                this.setState({_loading: true})
                var values = {
                    CHECKOUT: JSON.stringify(this.state.checkout),
                    TOTAL: this.state.valorbruto,
                    PRICE: this.state.valorfinal,
                    PAID: this.state.valorpago,
                    CHANGE: this.state.valortroco,
                    CPF: this.state.cpf,
                    PAYMENT_ID: this.state.formapagamento,
                    CREDIT: this.state.valorcredito,
                    DISCOUNT: this.state.valordesconto,
                    APPLY_TAX: (this.state.aplicartaxa) ? 1 : 0,
                    PRODUCTS: JSON.stringify(this.state.selecionados)
                }
                POST_API('/sale/save-checkout.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })

                        var mapForm = document.createElement("form");
                        mapForm.target = "_blank";    
                        mapForm.style = "display: none"
                        mapForm.method = "POST";
                        mapForm.action = `${URL_API}/reports/nf-sale.php`;

                        var mapInput2 = document.createElement("input");
                        mapInput2.type = "text";
                        mapInput2.name = "token";
                        mapInput2.value = getToken()
                        mapForm.appendChild(mapInput2);

                        var mapInput3 = document.createElement("input");
                        mapInput3.type = "text";
                        mapInput3.name = "master";
                        mapInput3.value = JSON.stringify({ID: res.ID})
                        mapForm.appendChild(mapInput3);

                        document.body.appendChild(mapForm);
                        mapForm.submit();

                        this.onCancelSale()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => this.setState({_loading: false}))
            } else {
                message.warning('Selecione a forma de pagamento')
            }
        } else {
            message.warning('Selecione pelo menos um produto')
        }


    }

    onCloseCheckout = () => {
        var self = this
        Modal.confirm({
            title: 'Deseja fechar o caixa "'+self.state.checkout.NAME+'"?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() { 
                self.setState({
                    modalFechamento: true,
                    moeda5: 0,
                    moeda10: 0,
                    moeda25: 0,
                    moeda50: 0,
                    moeda1: 0,
                    nota2: 0,
                    nota5: 0,
                    nota10: 0,
                    nota20: 0,
                    nota50: 0,
                    nota100: 0,
                    nota200: 0,
                    totalfechamento: 0
                })
                
            },
            onCancel() {},
        })
    }

    onBlurFechamento = () => {
        var value = 0;
        value += 0.05*this.state.moeda5
        value += 0.10*this.state.moeda10
        value += 0.25*this.state.moeda25
        value += 0.50*this.state.moeda50
        value += 1*this.state.moeda1
        value += 2*this.state.nota2
        value += 5*this.state.nota5
        value += 10*this.state.nota10
        value += 20*this.state.nota20
        value += 50*this.state.nota50
        value += 100*this.state.nota100
        value += 200*this.state.nota200

        this.setState({totalfechamento: Number(value).toFixed(2)})
    }

    onSendFechamento = () => {
        var self = this
        Modal.confirm({
            title: 'Todos os valores estão corretos?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() { 

                var params = {
                    MOEDA_5: self.state.moeda5,
                    MOEDA_10: self.state.moeda10,
                    MOEDA_25: self.state.moeda25,
                    MOEDA_50: self.state.moeda50,
                    MOEDA_1: self.state.moeda1,
                    NOTA_2: self.state.nota2,
                    NOTA_5: self.state.nota5,
                    NOTA_10: self.state.nota10,
                    NOTA_20: self.state.nota20,
                    NOTA_50: self.state.nota50,
                    NOTA_100: self.state.nota100,
                    NOTA_200: self.state.nota200
                }

                POST_API('/checkout/close.php', { token: getToken(), ID: self.state.checkout.ID, master: JSON.stringify(params) }).then(rs => rs.json()).then(res => {
                    if (!res.return) {
                        message.error(res.msg)
                    } else {
                        message.success(res.msg)

                        var mapForm = document.createElement("form");
                        mapForm.target = "_blank";    
                        mapForm.style = "display: none"
                        mapForm.method = "POST";
                        mapForm.action = `${URL_API}/reports/close-checkout.php`;

                        var mapInput2 = document.createElement("input");
                        mapInput2.type = "text";
                        mapInput2.name = "token";
                        mapInput2.value = getToken()
                        mapForm.appendChild(mapInput2);

                        var mapInput3 = document.createElement("input");
                        mapInput3.type = "text";
                        mapInput3.name = "master";
                        mapInput3.value = JSON.stringify({ID: self.state.checkout.ID})
                        mapForm.appendChild(mapInput3);

                        document.body.appendChild(mapForm);
                        mapForm.submit();

                        self.setState({checkout: null, _navigate: true})
                    }
                })     

            },
            onCancel() {},
        })
        
    }

    onSaveEdit = () => {

        var list = this.state.selecionados;
        list[this.state.editIndex] = this.state.editValues;
        this.setState({ selecionados: list, modaledit: false, editIndex: -1, editValues: null })
        setTimeout(() => {
            this.onBlurQTY()
        }, 500);

    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/dashboard'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    {this.state.checkout ? (
                        <Row gutter={[8,8]} align={'top'}>
                            <Col xs={24} md={16}>
                                <Card>
                                    <Row gutter={[8,8]}>
                                        <Col xs={24} md={20}>
                                            <Input value={this.state.search} onChange={this.onSearch} size="large" placeholder="Pesquisar produto..." addonAfter={<Button type="text" shape="circle"><IoBarcodeOutline size={22} /></Button>} />
                                        </Col>
                                        <Col xs={20} md={3}>
                                            <Input size="large" value={this.state.qtde} onChange={(v) => this.setState({qtde: v.target.value})} placeholder="Qtde." type="number" />
                                        </Col>
                                        <Col xs={4} md={1}>
                                            <Button onClick={this.addSelect} size="large" type="text" shape="circle" className="btn-text"><IoAddCircleOutline color="var(--primary)" size={25}/></Button>
                                        </Col>
                                    </Row>
                                </Card>
                                {this.state._search ? <center><Spin style={{marginTop: 10}} /></center> : (
                                    <>
                                    {this.state._lista.length > 0 ? (
                                        <Row gutter={[8,8]} style={{marginTop: 5}}>
                                            {this.state._lista.map((v, i) => (
                                                <Col span={24} key={i}>
                                                    <Card size="small" title={v.BARCODE} onClick={() => this.onSelect(v)}>
                                                        <Row align={'middle'}>
                                                            <Carousel style={{width: 125, height: 125}} autoplay>
                                                                {v.PHOTOS.map((pv, pi) => (
                                                                    <div key={pi} style={{width: 125, height: 125}}>
                                                                        <Image style={{width: 125, height: 125, maxWidth: '100%', objectFit: 'cover'}} src={pv.URL} preview={false} />
                                                                    </div>
                                                                ))}
                                                            </Carousel>
                                                            <div style={{marginLeft: 10}}>
                                                                <Typography><span style={{fontWeight: 700}}>Nome:</span> {v.PRODUCT_NAME}</Typography>
                                                                <Typography><span style={{fontWeight: 700}}>Preço:</span> {v.PRICE_SALE_FORMAT}</Typography>
                                                                <Typography><span style={{fontWeight: 700}}>Tamanho:</span> {v.SIZE}</Typography>
                                                                <Typography><span style={{fontWeight: 700}}>Cor:</span> {v.COLOR}</Typography>
                                                            </div>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    ) : this.state.search !== "" ? <Typography style={{marginTop: 10, textAlign: 'center'}}>Nenhum produto encontrado</Typography> : null }
                                    </>
                                )}
                                <Card title="Carrinho" size="small" style={{marginTop: 10}}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={this.state.selecionados}
                                        renderItem={(item, index) => (
                                            <List.Item key={index}>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Carousel style={{width: 70, height: 70}} autoplay>
                                                            {item.PHOTOS.map((pv, pi) => (
                                                                <div key={pi} style={{width: 70, height: 70}}>
                                                                    <Image style={{width: 70, height: 70, maxWidth: '100%', objectFit: 'cover'}} src={pv.URL} preview={false} />
                                                                </div>
                                                            ))}
                                                        </Carousel>
                                                    }
                                                    title={`(${item.QTDE}) ${item.BARCODE} | ${item.PRODUCT_NAME}`}
                                                    description={`Total: R$ ${String(item.TOTAL).replace('.', ',')}`}
                                                />
                                                <div>
                                                    <Button type="link" title="Editar Valor" onClick={() => this.onEditProd(item, index)}><IoPencil size={20} /></Button>
                                                    <Button type="link" title="Cancelar" onClick={() => this.onRemoveProd(index)}><IoTrash size={20} color="red" /></Button>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} md={8}>
                                <Card>
                                    <Row gutter={[16,16]}>
                                        <Col span={24}>
                                            <ReactInputMask value={this.state.cpf} onChange={this.onChangeCPF} size="large" placeholder="CPF Cliente" mask={'999.999.999-99'} className="ant-input ant-input-lg css-dev-only-do-not-override-zjzpde css-zjzpde" />
                                        </Col>
                                        <Col span={14}>
                                            <Input value={this.state.valorcredito} onChange={this.onChangeCredito} onBlur={this.onBlurQTY} size="large" placeholder="Crédito" type="number" addonBefore={'R$'} />
                                        </Col>
                                        <Col span={10}>
                                            <Input value={this.state.valordesconto} onChange={this.onChangeDesconto} onBlur={this.onBlurQTY} size="large" placeholder="Desconto" type="number" addonAfter={'%'} />
                                        </Col>
                                        <Col span={24}>
                                            <Select placeholder="Forma de pagamento" size="large" style={{width: '100%'}} value={this.state.formapagamento} allowClear onChange={(a, b) => {this.onChangeFormaPagamento(a); this.onChangeTaxa(b['data-tax'])}} onBlur={this.onBlurQTY}>
                                                <Select.Option disabled value="0">Selecione...</Select.Option>
                                                { this.state.filters.FILTER_PAYMENT.map((v, i) => (
                                                    <Select.Option data-tax={v.TAX} key={i} value={v.value}>{v.text}</Select.Option>
                                                )) }
                                            </Select>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox checked={this.state.aplicartaxa} onChange={this.onChangeAplicarTaxa}>Aplicar Juros</Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Statistic 
                                                title="SUBTOTAL"
                                                value={this.state.valorbruto}
                                                prefix={'R$'}
                                                decimalSeparator=","
                                                groupSeparator="."
                                                precision={2}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic 
                                                title="TOTAL"
                                                value={this.state.valorfinal}
                                                prefix={'R$'}
                                                decimalSeparator=","
                                                groupSeparator="."
                                                precision={2}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Input size="large" placeholder="Pago" type="number" addonBefore={'R$'} value={this.state.valorpago} onChange={this.onChangePago} onBlur={this.onBlurPago} />
                                        </Col>
                                        <Col span={12}>
                                            <Input size="large" value={this.state.valortroco} placeholder="Troco" type="number" addonBefore={'R$'}  onChange={this.onChangeTroco} />
                                        </Col>
                                    </Row>
                                </Card>
                                <Row gutter={[8,8]} style={{marginTop: 8}}>
                                    <Col span={12}>
                                        <Button loading={this.state._loading} onClick={this.onCancelSale} size="large" block type="default" className="btn-default">Cancelar venda</Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button loading={this.state._loading} onClick={this.onSend} size="large" block type="primary" className="btn-primary">Concluir venda</Button>
                                    </Col>
                                    <Col span={24}>
                                        <Button onClick={this.onCloseCheckout} size="large" block type="primary" className="btn-danger">Fechar Caixa</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : <PageLoad /> }
                    <Modal title="Editar preço" okText="Salvar" cancelText="Cancelar" open={this.state.modaledit} onOk={this.onSaveEdit} onCancel={() => this.setState({modaledit: false})}>
                        <Typography><b>Código de barras:</b> {this.state.editValues?.BARCODE}</Typography>
                        <Typography><b>Produto:</b> {this.state.editValues?.PRODUCT_NAME}</Typography>
                        <Typography><b>Total:</b> R$ {this.state.editValues?.TOTAL}</Typography>
                        <Typography><b>Valor unitário:</b></Typography>
                        <InputNumber addonBefore="R$" value={this.state.editValues?.PRICE_SALE} onChange={(v) => this.setState({ editValues: { ...this.state.editValues, PRICE_SALE: v, TOTAL: v*this.state.editValues.QTDE } })} />
                    </Modal>
                    <Modal title="Abrir Caixa" okText="Abrir" cancelText="Cancelar" open={this.state.modal} onOk={this.openCheckout} onCancel={() => this.setState({_navigate: true})}>
                        <Form layout="vertical">
                            <Row gutter={[8,8]}>
                                <Col span={14}>
                                    <Form.Item label="Selecione um caixa">
                                        <Select allowClear onChange={(v) => this.setState({select: v})}>
                                            <Select.Option disabled>Selecione...</Select.Option>
                                            { this.state.caixas.map((v, i) => (
                                                <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                            )) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="Valor de Abertura">
                                        <Input addonBefore="R$" type="number" value={this.state.value} onChange={(v) => this.setState({value: v.target.value})} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    <Modal title={`Fechar Caixa "${this.state.checkout?.NAME}"`} okText="Continuar" cancelText="Cancelar" open={this.state.modalFechamento} onOk={this.onSendFechamento} onCancel={() => this.setState({modalFechamento: false})} width={600}>
                        <Form layout="vertical">
                            <Row gutter={[8,8]}>
                                <Col span={24}>
                                    <Statistic 
                                        title="TOTAL"
                                        value={this.state.totalfechamento}
                                        prefix={'R$'}
                                        decimalSeparator=","
                                        groupSeparator="."
                                        precision={2}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="5 Centavos">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.moeda5} onChange={(v) => this.setState({moeda5: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="10 Centavos">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.moeda10} onChange={(v) => this.setState({moeda10: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="25 Centavos">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.moeda25} onChange={(v) => this.setState({moeda25: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="50 Centavos">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.moeda50} onChange={(v) => this.setState({moeda50: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="1 Real">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.moeda1} onChange={(v) => this.setState({moeda1: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="2 Reais">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.nota2} onChange={(v) => this.setState({nota2: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="5 Reais">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.nota5} onChange={(v) => this.setState({nota5: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="10 Reais">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.nota10} onChange={(v) => this.setState({nota10: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="20 Reais">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.nota20} onChange={(v) => this.setState({nota20: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="50 Reais">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.nota50} onChange={(v) => this.setState({nota50: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="100 Reais">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.nota100} onChange={(v) => this.setState({nota100: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="200 Reais">
                                        <Input onBlur={this.onBlurFechamento} placeholder="Quantidade" min={0} value={this.state.nota200} onChange={(v) => this.setState({nota200: v.target.value})} type="number" width={'100%'} style={{textAlign: 'center'}} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen