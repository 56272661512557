import React from "react";
import { Breadcrumb, Card, Col, Layout, Row, Statistic } from "antd";
import { Link, Navigate } from "react-router-dom";
import { Bar, Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
  } from 'chart.js';

import { POST_API, getColor, getEnv, getToken, titleWeb } from "../../../services/api";
import { IoBagOutline, IoPeopleOutline } from "react-icons/io5";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _dados: null,
        _graph: {
            SEMANAL: {LABEL: []},
            MENSAL: {LABEL: []},
        }
    }

    routes = [
        {title: <Link to="/dashboard">Dashboard</Link>}
    ]

    componentDidMount = async () => {
        titleWeb('Dashboard')
        await POST_API('/dashboard/kpi.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({_dados: res.data, _loading: false})
        })
        await POST_API('/dashboard/graphs.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({_graph: res.data, _loading: false})
        })
    }
    labels = []
    
    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={24}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col xs={12} md={6}>
                            <Card bordered={false}>
                                <Statistic
                                    loading={this.state._loading}
                                    title="Clientes"
                                    value={this.state._dados?.CLIENT}
                                    prefix={<IoPeopleOutline size={22} />}
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card bordered={false}>
                                <Statistic
                                    loading={this.state._loading}
                                    title="Total de Vendas"
                                    value={this.state._dados?.SALE}
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card bordered={false}>
                                <Statistic
                                    loading={this.state._loading}
                                    title="Estoque"
                                    value={this.state._dados?.STOCK}
                                    prefix={<IoBagOutline size={22} />}
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card bordered={false}>
                                <Statistic
                                    loading={this.state._loading}
                                    title="Faturamento"
                                    value={this.state._dados?.FAT}
                                    prefix={'R$'}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={12}>
                            <Card bordered={false} size="small">
                                <Bar options={{
                                    responsive: true,
                                    plugins: { legend: { display: false, position: 'top', }, title: { display: true, text: 'Vendas Por dia da Semana' },},
                                }} data={{
                                    labels: this.state._graph?.SEMANAL.LABEL,
                                    datasets: [{
                                        label: 'Venda',
                                        data: this.state._graph?.SEMANAL.LABEL.map((v) => this.state._graph?.SEMANAL[v]),
                                        backgroundColor: getColor(),
                                    },],
                                }} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12}>
                            <Card bordered={false} size="small">
                                <Bar options={{
                                    responsive: true,
                                    plugins: { legend: { display: false, position: 'top', }, title: { display: true, text: 'Vendas Por Mês' },},
                                }} data={{
                                    labels: this.state._graph?.MENSAL.LABEL,
                                    datasets: [{
                                        label: 'Venda',
                                        data: this.state._graph?.MENSAL.LABEL.map((v) => this.state._graph?.MENSAL[v]),
                                        backgroundColor: getColor(),
                                    },],
                                }} />
                            </Card>
                        </Col>
                        <Col xs={24} md={8}>
                            <Card bordered={false} size="small">
                                <Doughnut options={{
                                    responsive: true,
                                    plugins: { legend: { display: true, position: 'top', }, title: { display: true, text: 'Faturamento Hoje' },},
                                }} data={{
                                    labels: ['Contas a Pagar', 'Contas a Receber'],
                                    datasets: [{
                                        label: 'Faturamento Hpje',
                                        data: this.state._graph?.FAT_DIA,
                                        backgroundColor: [
                                            'rgba(255, 0, 55, 1)',
                                            'rgba(75, 206, 0, 1)',
                                        ],
                                    },],
                                }} />
                            </Card>
                        </Col>
                        <Col xs={24} md={8}>
                            <Card bordered={false} size="small">
                                <Doughnut options={{
                                    responsive: true,
                                    plugins: { legend: { display: true, position: 'top', }, title: { display: true, text: 'Faturamento Mês' },},
                                }} data={{
                                    labels: ['Contas a Pagar', 'Contas a Receber'],
                                    datasets: [{
                                        label: 'Faturamento Mês',
                                        data: this.state._graph?.FAT_MES,
                                        backgroundColor: [
                                            'rgba(255, 0, 55, 1)',
                                            'rgba(75, 206, 0, 1)',
                                        ],
                                    },],
                                }} />
                            </Card>
                        </Col>
                        <Col xs={24} md={8}>
                            <Card bordered={false} size="small">
                                <Doughnut options={{
                                    responsive: true,
                                    plugins: { legend: { display: true, position: 'top', }, title: { display: true, text: 'Faturamento Ano' },},
                                }} data={{
                                    labels: ['Contas a Pagar', 'Contas a Receber'],
                                    datasets: [{
                                        label: 'Faturamento Ano',
                                        data: this.state._graph?.FAT_ANO,
                                        backgroundColor: [
                                            'rgba(255, 0, 55, 1)',
                                            'rgba(75, 206, 0, 1)',
                                        ],
                                    },],
                                }} />
                            </Card>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;