import React from "react"
import { Avatar, Button, Col, Drawer, Image, Layout, Modal, Row, message } from "antd"

import { POST_API, delConfig, delToken, getEnv, getLogoNavbar, getToken, setColor, setLogoNavbar } from "../../services/api"
import { Link, Navigate, Outlet } from "react-router-dom"

import SidebarComponent from '../../components/Sidebar'
import { IoCartOutline, IoExitOutline, IoMenuOutline } from "react-icons/io5"
import { ExclamationCircleOutlined } from '@ant-design/icons';

class Screen extends React.Component {

    state = {
        _loading: false,
        _navigate: false,
        _menu: false,
        _user: null
    }

    componentDidMount = () => {
        if (getToken()) {
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (!res.return) {
                    this.setState({_navigate: true})
                } else {
                    this.setState({_user: res.user})
                    setLogoNavbar(res.account.LOGO)
                    setColor(res.account.COLOR_PRIMARY)
                    const favicon = document.getElementById("favicon");
                    favicon.setAttribute("href", res.account.FAVICON); 
                    document.querySelector(':root').style.setProperty('--primary', `${res.account.COLOR_PRIMARY}`);
                    document.querySelector(':root').style.setProperty('--hover', `${res.account.COLOR_SECONDARY}`);
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'login' })
            }).finally(() => {
                this.setState({_loading: false})
            })
        } else {
            this.setState({_navigate: true})
        }
    }

    onLogOut = () => {
        var self = this
        Modal.confirm({
            title: 'Sair do sistema?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() { delToken(); delConfig(); self.setState({_navigate: true}) },
            onCancel() {},
        })
    }

    onMenu = () => {
        this.setState({_menu: !this.state._menu})
    }

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/" />
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-content`}>
                    <Row className={`${getEnv()}-navbar`} align="middle" justify="space-between">
                        <Col>
                            <Row align={'middle'}>
                                <Button onClick={this.onMenu} shape="circle" className="btn-nav menu-button" type="link"><IoMenuOutline size={22} /></Button>
                                <Image style={{marginBottom: 10, objectFit: 'contain'}} src={getLogoNavbar()} preview={false} width='50px' height="50px" />
                            </Row>
                        </Col>
                        <Col>
                            <Row align={'middle'}>
                                <Link to="/checkout"><Button shape="circle" className="btn-nav" type="link"><IoCartOutline size={18} /></Button></Link>
                                <Button onClick={this.onLogOut} shape="circle" className="btn-nav" type="link"><IoExitOutline size={18} /></Button>
                                <Avatar shape="circle" size={40} src={this.state._user?.AVATAR} />
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={0} md={4} className={`${getEnv()}-sidebar`}>
                            <SidebarComponent />
                        </Col>
                        <Col xs={24} md={20}>
                            <Outlet />
                        </Col>
                    </Row>
                    <Drawer size="small" placement="left" open={this.state._menu} onClose={this.onMenu} width={300}>
                        <SidebarComponent clickOption={this.onMenu} />
                    </Drawer>
                </Layout.Content>
            </Layout>
        )
    }

}

export default Screen