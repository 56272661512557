import React from "react";
import { Breadcrumb, Button, Col, Form, Input, InputNumber, Layout, Modal, Row, Select, Spin, Switch, Table, Typography, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, getEnv, getToken, getUPLOADAPI, setPath, titleWeb } from "../../../../services/api";

// CONTROLLER
import Page403 from '../../../Controller/Page403';
import PageLoad from '../../../Controller/PageLoad';
import ReactInputMask from "react-input-mask";
import Search from "antd/es/input/Search";
import { IoPencil, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        type: false,
        address: {
            LOGRADOURO_DESTINATARIO: '',
            NUMERO_DESTINATARIO: '',
            COMPLEMENTO_DESTINATARIO: '',
            BAIRRO_DESTINATARIO: '',
            MUNICIPIO_DESTINATARIO: '',
            UF_DESTINATARIO: '',
        },
        products: [ ],
        openProduct: false,
        productValues: {},
        editProduct: -1,
        load: false,
        icms: ''
    }

    componentDidMount = async () => {
        titleWeb('NFe')
        await POST_API('/nfe/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({_dados: res.data})
            if (this.props.type === 'add') {
                this.setState({_screen: false})
            } else if (this.props.type === 'edit') {
                this.onView()
            }
        })
    }

    onView = async () => {
        POST_API('/nfe/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[6]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    defaultValue: res.data,
                    address: {
                        LOGRADOURO_DESTINATARIO: res.data.LOGRADOURO_DESTINATARIO,
                        NUMERO_DESTINATARIO: res.data.NUMERO_DESTINATARIO,
                        COMPLEMENTO_DESTINATARIO: res.data.COMPLEMENTO_DESTINATARIO,
                        BAIRRO_DESTINATARIO: res.data.BAIRRO_DESTINATARIO,
                        MUNICIPIO_DESTINATARIO: res.data.MUNICIPIO_DESTINATARIO,
                        UF_DESTINATARIO: res.data.UF_DESTINATARIO,
                    },
                    type: (res.data.CNPJ_DESTINATARIO != null && res.data.CNPJ_DESTINATARIO != ''),
                    products: res.itens
                })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})

        values = {
            ...values,
            ...this.state.address
        }

        POST_API('/nfe/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values), itens: JSON.stringify(this.state.products) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    buscaCEP = async (val) => {
        var cep = String(val.target.value).replace('-', '')
        this.setState({cepload: true})
        await fetch('https://viacep.com.br/ws/'+cep+'/json/').then(rs => rs.json()).then(res => {
            if (res.erro) {
                message.warning('CEP inválido')
            } else {
                this.setState({
                    address: {
                        LOGRADOURO_DESTINATARIO: res.logradouro,
                        BAIRRO_DESTINATARIO: res.bairro,
                        MUNICIPIO_DESTINATARIO: res.localidade,
                        UF_DESTINATARIO: res.uf,
                    }
                })
            }
        }).catch(() => {
            message.warning('Não foi possível estabelecer uma conexão com o sistema')
        }).finally(() => this.setState({cepload: false}))

    }

    onAddProduct = () => {
        this.setState({ productValues: { NUMERO_ITEM: this.state.products.length+1 } })
        setTimeout(() => {
            this.setState({ openProduct: true })
        }, 500);
    }

    onEditProduct = (i) => {
        this.setState({ productValues: this.state.products[i], editProduct: i })
        setTimeout(() => {
            this.setState({ openProduct: true })
        }, 500);
    }

    onDelProduct = (i) => {
        var self = this
        Modal.confirm({
            title: 'Excluir produto?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                self.setState({load: true})
                var list = self.state.products;
                list.splice(i, 1);

                list.map((v, i) => {
                    list[i].NUMERO_ITEM = i+1
                })

                self.setState({ products: list })
                setTimeout(() => {
                    self.setState({load: false})
                }, 1000);
            },
            onCancel() {},
        })
    }

    onSendProducts = (values) => {
        this.setState({load: true})
        var list = this.state.products;

        if (this.state.editProduct > -1) {
            list[this.state.editProduct] = values
        } else {
            values.key = values.NUMERO_ITEM;
            list.push(values)
        }

        this.setState({ products: list, productValues: {}, openProduct: false, editProduct: -1 })
        setTimeout(() => {
            this.setState({load: false})
        }, 1000);
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/fiscal/nfe'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/fiscal">Documentos Fiscais</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to="/dashboard/fiscal/nfe">NFe</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{ this.props.type === 'add' ? 'Novo' : 'Atualizar' }</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={'/dashboard/fiscal/nfe'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Dados Gerais</Typography>
                                            <div className="div_item_button">
                                                <Typography className="title_item_button">Carregar Venda</Typography>
                                            </div>
                                        </Col>
                                        <Col md={24} xs={24}>
                                            <Form.Item label="Empresa Emitente" name="FOCUSNFE_CONF_ID" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select style={{width: '100%'}}>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados?.FOCUSNFE_CONF_ID.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={10} xs={24}>
                                            <Form.Item label="Natureza da Operação" name="NATUREZA_OPERACAO" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select style={{width: '100%'}}>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados?.NATUREZA_OPERACAO.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={4} xs={24}>
                                            <Form.Item label="Tipo de Documento" name="TIPO_DOCUMENTO" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select style={{width: '100%'}} allowClear={true}>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados?.TIPO_DOCUMENTO.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={5} xs={24}>
                                            <Form.Item label="Local de Destino" name="LOCAL_DESTINO" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select style={{width: '100%'}} allowClear={true}>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados?.LOCAL_DESTINO.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={5} xs={24}>
                                            <Form.Item label="Finalidade de Emissão" name="FINALIDADE_EMISSAO" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select style={{width: '100%'}} allowClear={true}>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados?.FINALIDADE_EMISSAO.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={5} xs={24}>
                                            <Form.Item label="Consumidor Final" name="CONSUMIDOR_FINAL" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select style={{width: '100%'}} allowClear={true}>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados?.CONSUMIDOR_FINAL.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={9} xs={24}>
                                            <Form.Item label="Presença do Comprador" name="PRESENCA_COMPRADOR" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select style={{width: '100%'}} allowClear={true}>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados?.PRESENCA_COMPRADOR.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={5} xs={24}>
                                            <Form.Item label="Data Emissão" name="DATA_EMISSAO" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type="date" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={5} xs={24}>
                                            <Form.Item label="Data Entrada/Saída" name="DATA_ENTRADA_SAIDA" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type="date" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={24} xs={24}>
                                            <Form.Item label="Informações Adicionais" name="INFORMACOES_ADICIONAIS_CONTRIBUINTE">
                                                <Input.TextArea rows={3} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Destinatário</Typography>
                                            <div className="div_item_button">
                                                <Typography className="title_item_button">Carregar Cliente</Typography>
                                            </div>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Form.Item label={ this.state.type ? "CNPJ" : "CPF" } name={ this.state.type ? "CNPJ_DESTINATARIO" : "CPF_DESTINATARIO" } rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <ReactInputMask mask={ this.state.type ? "99.999.999/9999-99" : "999.999.999-99" }>
                                                    {(inputProps) => <Input {...inputProps} /> }
                                                </ReactInputMask>
                                            </Form.Item>
                                            <Switch checked={this.state.type} onChange={(v) => this.setState({type: v})} style={{position: 'absolute', right: 12, top: 35}} checkedChildren='PJ' unCheckedChildren='PF' />
                                        </Col>
                                        <Col xs={24} md={9}>
                                            <Form.Item label={ this.state.type ? "Razão Social" : "Nome" } name="NOME_DESTINATARIO" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="E-mail" name="EMAIL_DESTINATARIO">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Telefone" name="TELEFONE_DESTINATARIO">
                                                <ReactInputMask mask={"(99) 99999-9999"}>
                                                    {(inputProps) => <Input {...inputProps} /> }
                                                </ReactInputMask>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Form.Item label="CEP" name="CEP_DESTINATARIO">
                                                <ReactInputMask mask={'99999-999'} onBlur={this.buscaCEP}>
                                                    {(inputProps) => <Input {...inputProps} /> }
                                                </ReactInputMask>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={15}>
                                            <Form.Item label="Logradouro">
                                                <Input type='text' value={this.state.address.LOGRADOURO_DESTINATARIO} onChange={(v) => this.setState({ address: { ...this.state.address, LOGRADOURO_DESTINATARIO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Número">
                                                <Input type='text' value={this.state.address.NUMERO_DESTINATARIO} onChange={(v) => this.setState({ address: { ...this.state.address, NUMERO_DESTINATARIO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Complemento">
                                                <Input type='text' value={this.state.address.COMPLEMENTO_DESTINATARIO} onChange={(v) => this.setState({ address: { ...this.state.address, COMPLEMENTO_DESTINATARIO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Bairro">
                                                <Input type='text' value={this.state.address.BAIRRO_DESTINATARIO} onChange={(v) => this.setState({ address: { ...this.state.address, BAIRRO_DESTINATARIO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Município">
                                                <Input type='text' value={this.state.address.MUNICIPIO_DESTINATARIO} onChange={(v) => this.setState({ address: { ...this.state.address, MUNICIPIO_DESTINATARIO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={2}>
                                            <Form.Item label="UF">
                                                <Input type='text' maxLength={2} value={this.state.address.UF_DESTINATARIO} onChange={(v) => this.setState({ address: { ...this.state.address, UF_DESTINATARIO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Inscrição Estadual" name="INSCRICAO_ESTADUAL_DESTINATARIO">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col md={18} xs={24}>
                                            <Form.Item label="Indicador Inscrição Estadual" name="INDICADOR_INSCRICAO_ESTADUAL_DESTINATARIO" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select style={{width: '100%'}} allowClear={true}>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados?.INDICADOR_INSCRICAO_ESTADUAL_DESTINATARIO.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Produtos</Typography>
                                            <div className="div_item_button">
                                                <Typography className="title_item_button" onClick={this.onAddProduct}>Adicionar Manual</Typography>
                                                <Typography className="title_item_button">Adicionar Base</Typography>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            { this.state.load ? <center><Spin /></center> : (
                                                <Table pagination={false} size="small" dataSource={this.state.products}
                                                    columns={[
                                                        { title: '#', dataIndex: 'NUMERO_ITEM', key: 'NUMERO_ITEM', width: 50, align: 'center', sorter: false, showSorterTooltip: false },
                                                        { title: 'Código', dataIndex: 'CODIGO_PRODUTO', key: 'CODIGO_PRODUTO', width: 150, align: 'center', sorter: false, showSorterTooltip: false },
                                                        { title: 'Descrição', dataIndex: 'DESCRICAO', key: 'DESCRICAO', sorter: false, showSorterTooltip: false },
                                                        { title: 'Quantidade', dataIndex: 'QUANTIDADE_COMERCIAL', key: 'QUANTIDADE_COMERCIAL', width: 100, align: 'center', sorter: false, showSorterTooltip: false },
                                                        { title: 'Valor', dataIndex: 'VALOR_UNITARIO_COMERCIAL', key: 'VALOR_UNITARIO_COMERCIAL', width: 150, align: 'center', sorter: false, showSorterTooltip: false },
                                                        { title: 'Ações', dataIndex: 'actions', key: 'actions', width: 100, align: 'center', sorter: false, showSorterTooltip: false, render: (text, record) => (
                                                            <Row gutter={[4,4]} className="table__actions">
                                                                <Col><Button onClick={() => this.onEditProduct(record.NUMERO_ITEM-1)} className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Col>
                                                                <Col><Button onClick={() => this.onDelProduct(record.NUMERO_ITEM-1)} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                                                            </Row>
                                                        ) },
                                                    ]}
                                                />
                                            ) }
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Valores e Frete</Typography>
                                        </Col>
                                        <Col md={8} xs={24}>
                                            <Form.Item label="Modalidade Frete" name="MODALIDADE_FRETE" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select style={{width: '100%'}} allowClear={true}>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados?.MODALIDADE_FRETE.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={4} xs={24}>
                                            <Form.Item label="Valor Frete" name='VALOR_FRETE' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <InputNumber addonBefore='R$' decimalSeparator="," />
                                            </Form.Item>
                                        </Col>
                                        <Col md={4} xs={24}>
                                            <Form.Item label="Valor Seguro" name='VALOR_SEGURO' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <InputNumber addonBefore='R$' decimalSeparator="," />
                                            </Form.Item>
                                        </Col>
                                        <Col md={4} xs={24}>
                                            <Form.Item label="Valor Produtos" name='VALOR_PRODUTOS' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <InputNumber addonBefore='R$' decimalSeparator="," />
                                            </Form.Item>
                                        </Col>
                                        <Col md={4} xs={24}>
                                            <Form.Item label="Valor Total" name='VALOR_TOTAL' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <InputNumber addonBefore='R$' decimalSeparator="," />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                    <Modal width={1000} open={this.state.openProduct} onCancel={() => null} destroyOnClose={true} closable={false} footer={false}>
                        <Form layout="vertical" initialValues={this.state.productValues} onFinish={this.onSendProducts}>
                            <Row gutter={[16,0]} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                <Col span={24}>
                                    <Typography className="title_item">Produto</Typography>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item label="Item" name='NUMERO_ITEM' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input disabled type='text' />
                                    </Form.Item>
                                </Col>
                                <Col md={5} xs={24}>
                                    <Form.Item label="Código Produto" name='CODIGO_PRODUTO' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                                <Col md={16} xs={24}>
                                    <Form.Item label="Descrição" name='DESCRICAO' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item label="Unidade" name='UNIDADE_COMERCIAL' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Select style={{width: '100%'}} allowClear={true}>
                                            <Select.Option disabled>Selecione...</Select.Option>
                                            { this.state._dados?.UNIDADE_COMERCIAL.map((v, i) => (
                                                <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                            )) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item label="Quantidade" name='QUANTIDADE_COMERCIAL' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input type='number' min={0} />
                                    </Form.Item>
                                </Col>
                                <Col md={4} xs={24}>
                                    <Form.Item label="Valor" name='VALOR_UNITARIO_COMERCIAL' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <InputNumber addonBefore='R$' decimalSeparator="," />
                                    </Form.Item>
                                </Col>
                                <Col md={14} xs={24}>
                                    <Form.Item label="ICMS Origem" name='ICMS_ORIGEM' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Select style={{width: '100%'}} allowClear={true}>
                                            <Select.Option disabled>Selecione...</Select.Option>
                                            { this.state._dados?.ICMS_ORIGEM.map((v, i) => (
                                                <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                            )) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={5} xs={24}>
                                    <Form.Item label="Código NCM" name='CODIGO_NCM' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Search maxLength={8} onSearch={() => {}} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item label="Código CEST" name='CEST'>
                                        <Input type="text" maxLength={7} />
                                    </Form.Item>
                                </Col>
                                <Col md={4} xs={24}>
                                    <Form.Item label="Código CFOP" name='CFOP' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Search maxLength={4} onSearch={() => {}} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item label="ICMS" name='ICMS_SITUACAO_TRIBUTARIA' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Search maxLength={3} onChange={(v) => this.setState({icms: v.target.value})} onSearch={() => {}} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item label="PIS" name='PIS_SITUACAO_TRIBUTARIA' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Search maxLength={2} onSearch={() => {}} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item label="COFINS" name='COFINS_SITUACAO_TRIBUTARIA' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Search maxLength={2} onSearch={() => {}} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col md={3} xs={24}>
                                    <Form.Item label="IPI" name='IPI_SITUACAO_TRIBUTARIA'>
                                        <Search maxLength={2} onSearch={() => {}} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Typography className="title_item">Dados Complementares</Typography>
                                </Col>
                                {  ['000', '010', '020', '051', '070', '090', '900'].includes(this.state.icms) ? (
                                    <>
                                    <Col md={11} xs={24}>
                                        <Form.Item label="ICMS Modalidade Base de Cálculo" name='ICMS_MODALIDADE_BASE_CALCULO' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                            <Select style={{width: '100%'}} allowClear={true}>
                                                <Select.Option disabled>Selecione...</Select.Option>
                                                { this.state._dados?.ICMS_MODALIDADE_BASE_CALCULO.map((v, i) => (
                                                    <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                )) }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={5} xs={24}>
                                        <Form.Item label="ICMS Base de Cálculo" name='ICMS_BASE_CALCULO' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                            <InputNumber decimalSeparator="," addonBefore="R$" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} xs={24}>
                                        <Form.Item label="ICMS Aliquota" name='ICMS_ALIQUOTA' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                            <InputNumber decimalSeparator="," addonAfter="%" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} xs={24}>
                                        <Form.Item label="ICMS Valor" name='ICMS_VALOR' rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                            <InputNumber decimalSeparator="," addonBefore="R$" />
                                        </Form.Item>
                                    </Col>
                                    </>
                                ) : null }
                                <Col span={24}>
                                    <Button htmlType="submit" type="primary" shape="round" className="float-right" style={{marginLeft: 10}}>{ this.state.editProduct > -1 ? 'Atualizar' : 'Adicionar' }</Button>
                                    <Button type="default" shape="round" className="float-right" onClick={() => this.setState({openProduct: false, productValues: {}})}>Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;