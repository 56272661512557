import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Select, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, getEnv, getToken, getUPLOADAPI, setPath, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import conf from './conf.json';
import ReactInputMask from "react-input-mask";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        defaultValue: null
    }

    componentDidMount = async () => {
        titleWeb('Empresas')
        setPath('account')
        await POST_API('/account/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({_dados: res.data})
            if (this.props.type === 'add') {
                this.setState({_screen: false})
            } else if (this.props.type === 'edit') {
                this.onView()
            }
        })
    }

    onView = async () => {
        POST_API('/account/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/account/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/account'} />)
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/account">Empresas</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={'/dashboard/account'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`${getEnv()}-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="E-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="CNPJ" name="CPFCNPJ" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <ReactInputMask mask={'99.999.999/9999-99'} className="ant-input css-dev-only-do-not-override-zjzpde css-zjzpde" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Telefone" name="PHONE">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Ambiente" name="ENV_ID" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_ENV.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Plano" name="PLAN_ID" >
                                                <Select allowClear>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_PLAN.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={14} md={6}>
                                            <Form.Item label="Tipo" name="TYPE" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_TYPE.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={10} md={6}>
                                            <Form.Item label="Status" name="STATUS" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_STATUS.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Item label="Cor Primária" name="COLOR_PRIMARY" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='color'  />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Item label="Cor Secundária" name="COLOR_SECONDARY" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='color'  />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Logo" name="LOGO">
                                                <Upload maxCount={1} action={getUPLOADAPI} accept="image/png,image/jpg,image/jpeg">
                                                    <Button block>Selecionar imagem</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Favicon" name="FAVICON">
                                                <Upload maxCount={1} action={getUPLOADAPI} accept="image/png,image/jpg,image/jpeg">
                                                    <Button block>Selecionar imagem</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;