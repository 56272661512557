import React from "react";
import { Breadcrumb, Button, Col, Form, Image, Input, Layout, Modal, Row, Select, Switch, Typography, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, getEnv, getToken, setPath, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import conf from './conf.json';
import ReactInputMask from "react-input-mask";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: { ACCOUNT_ID: [], REGIME_TRIBUTARIO: [] },
        _params: window.location.href.split('/'),
        defaultValue: null,
        type: true,
        typecont: false,
        cert: 'Ausente',
        pass: '',
        base: '',
        logo: '',
        cepload: false,
        address: {
            LOGRADOURO: '',
            NUMERO: '',
            COMPLEMENTO: '',
            BAIRRO: '',
            MUNICIPIO: '',
            UF: '',
        },
        checked: {
            HABILITA_NFE: false,
            HABILITA_NFCE: false,
            HABILITA_NFSE: false,
            HABILITA_CTE: false,
            HABILITA_MDFE: false,
            ENVIAR_EMAIL_DESTINATARIO: false,
            ENVIAR_EMAIL_HOMOLOGACAO: false,
            DISCRIMINA_IMPOSTOS: false,
        }
    }

    componentDidMount = async () => {
        setPath('focusnfe')
        titleWeb('FocusNFe')
        await POST_API('/focusnfe/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({_dados: res.data})
            if (this.props.type === 'add') {
                this.setState({_screen: false})
            } else if (this.props.type === 'edit') {
                this.onView()
            }
        })
    }

    onView = async () => {
        POST_API('/focusnfe/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    defaultValue: res.data,
                    address: {
                        LOGRADOURO: res.data.LOGRADOURO,
                        NUMERO: res.data.NUMERO,
                        COMPLEMENTO: res.data.COMPLEMENTO,
                        BAIRRO: res.data.BAIRRO,
                        MUNICIPIO: res.data.MUNICIPIO,
                        UF: res.data.UF,
                    },
                    checked: {
                        HABILITA_NFE: (res.data.HABILITA_NFE > 0) ? true : false,
                        HABILITA_NFCE: (res.data.HABILITA_NFCE > 0) ? true : false,
                        HABILITA_NFSE: (res.data.HABILITA_NFSE > 0) ? true : false,
                        HABILITA_CTE: (res.data.HABILITA_CTE > 0) ? true : false,
                        HABILITA_MDFE: (res.data.HABILITA_MDFE > 0) ? true : false,
                        ENVIAR_EMAIL_DESTINATARIO: (res.data.ENVIAR_EMAIL_DESTINATARIO > 0) ? true : false,
                        ENVIAR_EMAIL_HOMOLOGACAO: (res.data.ENVIAR_EMAIL_HOMOLOGACAO > 0) ? true : false,
                        DISCRIMINA_IMPOSTOS: (res.data.DISCRIMINA_IMPOSTOS > 0) ? true : false,
                    },
                    base: res.data.ARQUIVO_CERTIFICADO_BASE64,
                    pass: res.data.SENHA_CERTIFICADO,
                    logo: res.data.ARQUIVO_LOGO_BASE64,
                    type: (res.data.CNPJ != null),
                    typecont: String(res.data.CPF_CNPJ_CONTABILIDADE).length > 14
                })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})

        if (this.state.type) {
            values.CPF = ''
        } else {
            values.CNPJ = ''
        }

        values = {
            ...values,
            ...this.state.address,
            ...this.state.checked,
            ARQUIVO_CERTIFICADO_BASE64: this.state.base,
            SENHA_CERTIFICADO: this.state.pass,
            ARQUIVO_LOGO_BASE64: this.state.logo,
        }
        POST_API('/focusnfe/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    buscaCEP = async (val) => {
        var cep = String(val.target.value).replace('-', '')
        this.setState({cepload: true})
        await fetch('https://viacep.com.br/ws/'+cep+'/json/').then(rs => rs.json()).then(res => {
            if (res.erro) {
                message.warning('CEP inválido')
            } else {
                this.setState({
                    address: {
                        LOGRADOURO: res.logradouro,
                        BAIRRO: res.bairro,
                        MUNICIPIO: res.localidade,
                        UF: res.uf,
                    }
                })
            }
        }).catch(() => {
            message.warning('Não foi possível estabelecer uma conexão com o sistema')
        }).finally(() => this.setState({cepload: false}))

    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/focusnfe'} />)
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/focusnfe">FocusNFe</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={'/dashboard/focusnfe'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name="FOCUSNFE_ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Empresa</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Empresa Referente" name="ACCOUNT_ID" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados.ACCOUNT_ID.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Dados Principais</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label={ this.state.type ? "CNPJ" : "CPF" } name={ this.state.type ? "CNPJ" : "CPF" } rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <ReactInputMask disabled={this.props.type === 'edit'} mask={ this.state.type ? "99.999.999/9999-99" : "999.999.999-99" }>
                                                    {(inputProps) => <Input {...inputProps} /> }
                                                </ReactInputMask>
                                            </Form.Item>
                                            <Switch disabled={this.props.type === 'edit'} checked={this.state.type} onChange={(v) => this.setState({type: v})} style={{position: 'absolute', right: 12, top: 35}} checkedChildren='PJ' unCheckedChildren='PF' />
                                        </Col>
                                        <Col xs={24} md={10}>
                                            <Form.Item label={ this.state.type ? "Razão Social" : "Nome" } name="NOME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label={ "Certificado Digital: "+this.state.cert }>
                                                <Upload accept=".p12,.pfx" action={'#'} showUploadList={false} beforeUpload={(file) => {
                                                    Modal.info({
                                                        title: 'Senha do certificado',
                                                        content: <Input type="password" onChange={(v) => this.setState({pass: v.target.value})} />,
                                                        okText: 'Confirmar',
                                                        onOk: () => {
                                                            POST_API('/focusnfe/certificate.php', { pass: this.state.pass, file }).then(rs => rs.json()).then(res => {
                                                                if (res.return) {
                                                                    this.setState({cert: res.msg, base: res.cert})
                                                                } else {
                                                                    message.warning(res.msg)
                                                                }
                                                            }).catch(() => {
                                                                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                                                            })
                                                        }
                                                    })
                                                }}>
                                                    <Button block>Anexar Certificado</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Identificação</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label={ "Logo" }>
                                                { this.state.logo !== '' ? <Image width={200} src={'data:image/png;base64,'+this.state.logo} /> : null}
                                                <Upload accept="image/*" action={'#'} showUploadList={false} beforeUpload={(file) => {
                                                    POST_API('/focusnfe/logo.php', { file }).then(rs => rs.json()).then(res => {
                                                        if (res.return) {
                                                            this.setState({logo: res.logo})
                                                        } else {
                                                            message.warning(res.msg)
                                                        }
                                                    }).catch(() => {
                                                        message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                                                    })
                                                }}>
                                                    <Button style={{marginTop:10}}>Anexar Logo</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={7}>
                                            <Form.Item label="Nome Fantasia" name="NOME_FANTASIA" rules={[{required: this.state.type, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' disabled={!this.state.type} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Inscrição Estadual" name="INSCRICAO_ESTADUAL">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Inscrição Municipal" name="INSCRICAO_MUNICIPAL">
                                                <Input type='text'/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={9}>
                                            <Form.Item label="Regime Tributário" name="REGIME_TRIBUTARIO" rules={[{required: this.state.type, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados.REGIME_TRIBUTARIO.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Contato</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="E-mail" name="EMAIL">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Telefone" name="TELEFONE">
                                                <ReactInputMask mask={"(99) 99999-9999"}>
                                                    {(inputProps) => <Input {...inputProps} /> }
                                                </ReactInputMask>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Endereço</Typography>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Form.Item label="CEP" name="CEP">
                                                <ReactInputMask mask={'99999-999'} onBlur={this.buscaCEP}>
                                                    {(inputProps) => <Input {...inputProps} /> }
                                                </ReactInputMask>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={15}>
                                            <Form.Item label="Logradouro">
                                                <Input type='text' value={this.state.address.LOGRADOURO} onChange={(v) => this.setState({ address: { ...this.state.address, LOGRADOURO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Número">
                                                <Input type='text' value={this.state.address.NUMERO} onChange={(v) => this.setState({ address: { ...this.state.address, NUMERO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Complemento">
                                                <Input type='text' value={this.state.address.COMPLEMENTO} onChange={(v) => this.setState({ address: { ...this.state.address, COMPLEMENTO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Bairro">
                                                <Input type='text' value={this.state.address.BAIRRO} onChange={(v) => this.setState({ address: { ...this.state.address, BAIRRO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Município">
                                                <Input type='text' value={this.state.address.MUNICIPIO} onChange={(v) => this.setState({ address: { ...this.state.address, MUNICIPIO: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={2}>
                                            <Form.Item label="UF">
                                                <Input type='text' maxLength={2} value={this.state.address.UF} onChange={(v) => this.setState({ address: { ...this.state.address, UF: v.target.value } })} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Responsável</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Nome Responsável" name="NOME_RESPONSAVEL">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="CPF Responsável" name="CPF_RESPONSAVEL">
                                                <ReactInputMask mask={"999.999.999-99"}>
                                                    {(inputProps) => <Input {...inputProps} /> }
                                                </ReactInputMask>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Contabilidade</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label={ this.state.typecont ? "CNPJ" : "CPF" } name="CPF_CNPJ_CONTABILIDADE">
                                                <ReactInputMask mask={ this.state.typecont ? "99.999.999/9999-99" : "999.999.999-99" }>
                                                    {(inputProps) => <Input {...inputProps} /> }
                                                </ReactInputMask>
                                            </Form.Item>
                                            <Switch checked={this.state.typecont} onChange={(v) => this.setState({typecont: v})} style={{position: 'absolute', right: 12, top: 35}} checkedChildren='PJ' unCheckedChildren='PF' />
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Tokens</Typography>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Token Homologação" name="TOKEN_HOMOLOGACAO">
                                                <Input type='text' readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Token Produção" name="TOKEN_PRODUCAO">
                                                <Input type='text' readOnly={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Documentos Fiscais</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="HABILITA_NFE">
                                                <Switch checked={this.state.checked.HABILITA_NFE} onChange={(v) => this.setState({ checked: { ...this.state.checked, HABILITA_NFE: v } })} size="small" style={{marginRight: 6}} /> NFe
                                            </Form.Item>
                                        </Col>
                                        { this.state.checked.HABILITA_NFE ? (
                                            <>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - Homologação" name="SERIE_NFE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - Homologação" name="PROXIMO_NUMERO_NFE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - produção" name="SERIE_NFE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - produção" name="PROXIMO_NUMERO_NFE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            </>
                                        ) : null }
                                        <Col xs={24} md={24}>
                                            <Form.Item name="HABILITA_NFCE">
                                                <Switch checked={this.state.checked.HABILITA_NFCE} onChange={(v) => this.setState({ checked: { ...this.state.checked, HABILITA_NFCE: v } })} size="small" style={{marginRight: 6}} /> NFCe
                                            </Form.Item>
                                        </Col>
                                        { this.state.checked.HABILITA_NFCE ? (
                                            <>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - Homologação" name="SERIE_NFCE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - Homologação" name="PROXIMO_NUMERO_NFCE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - produção" name="SERIE_NFCE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - produção" name="PROXIMO_NUMERO_NFCE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="ID Token - Homologação" name="ID_TOKEN_NFCE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="CSC - Homologação" name="CSC_NFCE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="ID Token - produção" name="ID_TOKEN_NFCE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="CSC - produção" name="CSC_NFCE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            </>
                                        ) : null }
                                        <Col xs={24} md={24}>
                                            <Form.Item name="HABILITA_NFSE">
                                                <Switch checked={this.state.checked.HABILITA_NFSE} onChange={(v) => this.setState({ checked: { ...this.state.checked, HABILITA_NFSE: v } })} size="small" style={{marginRight: 6}} /> NFSe
                                            </Form.Item>
                                        </Col>
                                        { this.state.checked.HABILITA_NFSE ? (
                                            <>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - Homologação" name="SERIE_NFSE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - Homologação" name="PROXIMO_NUMERO_NFSE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - produção" name="SERIE_NFSE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - produção" name="PROXIMO_NUMERO_NFSE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Login Prefeitura" name="LOGIN_RESPONSAVEL">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Senha Prefeitura" name="SENHA_RESPONSAVEL">
                                                    <Input type='password' />
                                                </Form.Item>
                                            </Col>
                                            </>
                                        ) : null }
                                        <Col xs={24} md={24}>
                                            <Form.Item name="HABILITA_CTE">
                                                <Switch checked={this.state.checked.HABILITA_CTE} onChange={(v) => this.setState({ checked: { ...this.state.checked, HABILITA_CTE: v } })} size="small" style={{marginRight: 6}} /> CTe
                                            </Form.Item>
                                        </Col>
                                        { this.state.checked.HABILITA_CTE ? (
                                            <>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - Homologação" name="SERIE_CTE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - Homologação" name="PROXIMO_NUMERO_CTE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - produção" name="SERIE_CTE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - produção" name="PROXIMO_NUMERO_CTE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            </>
                                        ) : null }
                                        <Col xs={24} md={24}>
                                            <Form.Item name="HABILITA_MDFE">
                                                <Switch checked={this.state.checked.HABILITA_MDFE} onChange={(v) => this.setState({ checked: { ...this.state.checked, HABILITA_MDFE: v } })} size="small" style={{marginRight: 6}} /> MDFe
                                            </Form.Item>
                                        </Col>
                                        { this.state.checked.HABILITA_MDFE ? (
                                            <>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - Homologação" name="SERIE_MDFE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - Homologação" name="PROXIMO_NUMERO_MDFE_HOMOLOGACAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Série - produção" name="SERIE_MDFE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Próx. N° - produção" name="PROXIMO_NUMERO_MDFE_PRODUCAO">
                                                    <Input type='text' />
                                                </Form.Item>
                                            </Col>
                                            </>
                                        ) : null }
                                    </Row>
                                    <Row gutter={[16,0]} className={`${getEnv()}-form`} style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                                        <Col span={24}>
                                            <Typography className="title_item">Configurações</Typography>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="ENVIAR_EMAIL_DESTINATARIO">
                                                <Switch checked={this.state.checked.ENVIAR_EMAIL_DESTINATARIO} onChange={(v) => this.setState({ checked: { ...this.state.checked, ENVIAR_EMAIL_DESTINATARIO: v } })} size="small" style={{marginRight: 6}} /> (todos os documentos) Enviar email ao destinatário (produção)
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="ENVIAR_EMAIL_HOMOLOGACAO">
                                                <Switch checked={this.state.checked.ENVIAR_EMAIL_HOMOLOGACAO} onChange={(v) => this.setState({ checked: { ...this.state.checked, ENVIAR_EMAIL_HOMOLOGACAO: v } })} size="small" style={{marginRight: 6}} /> (todos os documentos) Enviar email ao destinatário (homologação)
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="DISCRIMINA_IMPOSTOS">
                                                <Switch checked={this.state.checked.DISCRIMINA_IMPOSTOS} onChange={(v) => this.setState({ checked: { ...this.state.checked, DISCRIMINA_IMPOSTOS: v } })} size="small" style={{marginRight: 6}} /> (NFe, NFCe) Discrimina impostos
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;