import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Select, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, getEnv, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import conf from './conf.json';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        defaultValue: null
    }

    componentDidMount = async () => {
        titleWeb('Categorias')
        await POST_API('/category/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({_dados: res.data})
            if (this.props.type === 'add') {
                this.setState({_screen: false})
            } else if (this.props.type === 'edit') {
                this.onView()
            }
        })
    }

    onView = async () => {
        POST_API('/category/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/category/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/category'} />)
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/category">Categorias</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={'/dashboard/category'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`${getEnv()}-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={18}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Categoria" name="CATEGORY_ID">
                                                <Select allowClear>
                                                    <Select.Option disabled value="0">Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_CATEGORY.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;