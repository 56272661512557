import React from "react";
import { Breadcrumb, Button, Col, Layout, Modal, Row, Table, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoBagAdd, IoImage, IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, getEnv, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import TableComponent from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';

import conf from './conf.json';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
                current: 1,
                total: 0,
                limit: 10
            },
        table_filter: null,
        table_sorter: null,
        table_select: [],
        table_select_variation: [],
    }

    componentDidMount = () => {
        titleWeb('Produtos')
        this.onRenderColumns().then(() => this.onRenderData())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/product/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/product/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select), selectedVariation: JSON.stringify(self.state.table_select_variation) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        await POST_API('/product/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({
                table_column: [
                    { title: 'Cod.', dataIndex: 'ID', key: 'product.ID', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: 'Nome', dataIndex: 'NAME', key: 'product.NAME', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: 'Marca', width: 180, dataIndex: 'BRAND_NAME', key: 'product.BRAND_ID', sorter: { compare: (a,b) => null }, showSorterTooltip: false, filters: res.data.FILTER_BRAND },
                    { title: 'Categoria', width: 180, dataIndex: 'CATEGORY_NAME', key: 'product.CATEGORY_ID', sorter: { compare: (a,b) => null }, showSorterTooltip: false, filters: res.data.FILTER_CATEGORY },
                    { title: 'Estoque', width: 100, dataIndex: 'QTY_STOCK', key: 'product.CATEGORY_ID', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: 'Ações', key: 'action', width: 100, render: (text, record) => (
                        <>
                            { this.props.type === 'list' ? (
                                <Row gutter={[4,4]} className="table__actions">
                                    <Col><Link to={verifyConfig(conf.edit) ? record.ID : '#'}><Button disabled={!verifyConfig(conf.edit)} className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col>
                                    <Col><Button disabled={!verifyConfig(conf.del)} onClick={() => this.onAction(record.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                                    <Col><Link to={verifyConfig(conf.add) ? record.ID+'/add' : '#'}><Button disabled={!verifyConfig(conf.add)} className="table__btn table__btn_primary" title='Adicionar Variação' type="primary" shape="circle" size="small"><IoBagAdd size={14} /></Button></Link></Col>
                                </Row>
                            ) : (
                                <Row gutter={[4,4]} className="table__actions">
                                    <Col><Button disabled={!verifyConfig(conf.rec)} onClick={() => this.onAction(record.ID, 'rec', 'Recuperar registro?')} className="table__btn table__btn_recover" title='Recuperar' type="primary" shape="circle" size='small'><IoReturnUpBackOutline size={14} /></Button></Col>
                                </Row>
                            ) }
                        </>
                    )},
                ]
            })
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onSelectVariation = (selectedRowKeys, selectedRows) => {
        this.setState({table_select_variation: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    onUpdateStock = (id, val) => {
        POST_API('/stock/save.php', { token: getToken(), master: JSON.stringify({QTY: val, ID: id, TYPE: 'U'}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.onRenderData()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        })
    }

    expandedRowRender = (row) => {

        var collums = [
            { title: 'Cod.', dataIndex: 'ID', width: 100, align: 'center', showSorterTooltip: false },
            { title: 'Cod. Barras', dataIndex: 'BARCODE', showSorterTooltip: false },
            { title: 'Cor', dataIndex: 'COLOR', width: 120, align: 'center', showSorterTooltip: false },
            { title: 'Tamanho', dataIndex: 'SIZE', width: 120, align: 'center', showSorterTooltip: false },
            { title: 'Preço', dataIndex: 'PRICE_SALE_FORMAT', width: 120, align: 'right', showSorterTooltip: false },
            { title: 'Estoque', dataIndex: 'QTY_STOCK', width: 100, align: 'center', showSorterTooltip: false, render: (text, record) => (
                <Typography.Paragraph style={{marginTop:10}} editable={{onChange: (val) => this.onUpdateStock(record.ID, val)}}>{ record.QTY_STOCK ? record.QTY_STOCK : 0 }</Typography.Paragraph>
            ) },
            { title: 'Ações', dataIndex: 'action', width: 100, align: 'center', showSorterTooltip: false, render: (text, record) => (
                <Row gutter={[4,4]} className="table__actions">
                    <Col><Link to={verifyConfig(conf.edit) ? row.ID+'/'+record.ID : '#'}><Button disabled={!verifyConfig(conf.edit)} className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col>
                    <Col><Button disabled={!verifyConfig(conf.del)} onClick={() => this.onAction(record.ID, 'del-variation', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                    <Col><Link to={verifyConfig(conf.add) ? row.ID+'/'+record.ID+'/gallery' : '#'}><Button disabled={!verifyConfig(conf.add)} className="table__btn table__btn_action_1" title='Galeria' type="primary" shape="circle" size="small"><IoImage size={14} /></Button></Link></Col>
                </Row>
            ) },
        ]

        var data = row.VARIACOES

        return (
            <Table
                size="small"
                columns={collums}
                dataSource={data}
                rowSelection={{onChange: this.onSelectVariation, getCheckboxProps: () => {}}}
                pagination={false}
                style={{marginBottom: 38}}
            />
        )
    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/product">Produtos</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{ this.props.type === 'list' ? 'Lista' : 'Lixeira' }</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        { this.props.type === 'list' ? (
                            <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                { this.state.table_select.length > 0 ? <Button disabled={!verifyConfig(conf.del)} onClick={() => this.onAction(null, 'del-select', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Selecionados ({this.state.table_select.length})</Button> : null }
                                { this.state.table_select_variation.length > 0 ? <Button disabled={!verifyConfig(conf.del)} onClick={() => this.onAction(null, 'del-select-variation', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Variações Selecionadas ({this.state.table_select_variation.length})</Button> : null }
                                <Link to={verifyConfig(conf.trash) ? 'trash' : '#'}><Button onClick={this.onChangePage} disabled={!verifyConfig(conf.trash)} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Lixeira</Button></Link>
                                <Link to={verifyConfig(conf.add) ? 'add' : '#'}><Button disabled={!verifyConfig(conf.add)} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link>
                            </Col>
                        ) : (
                            <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                { this.state.table_select.length > 0 ? <Button disabled={!verifyConfig(conf.rec)} onClick={() => this.onAction(null, 'rec-select', 'Recuperar registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Recuperar Selecionados ({this.state.table_select.length})</Button> : null }
                                <Link to={'/dashboard/product'}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                            </Col>
                        ) }
                        <Col span={24}>
                            <TableComponent
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                                _expandedRows={(this.props.type === 'list') ? { expandedRowRender: this.expandedRowRender, defaultExpandedRowKeys: null } : null}
                                _rowSelection={{onChange: this.onSelect, getCheckboxProps: () => {}}}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;