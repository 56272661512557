import React from "react";
import { Breadcrumb, Button, Card, Col, Divider, Input, Layout, Modal, Row, Select, Tag, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoDownload, IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, URL_API, getEnv, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';

import conf from './conf.json';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _dados: null,
        myfilters: {
            CLIENT_ID: '',
            DATE_DUE_START: '',
            DATE_DUE_END: '',
            DATE_SALE_START: '',
            DATE_SALE_END: '',
        },
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
                current: 1,
                total: 0,
                limit: 10
            },
        table_filter: null,
        table_sorter: null,
        table_select: [],
    }

    componentDidMount = () => {
        titleWeb('Vendas')

        this.onRenderColumns().then(() => this.onRenderData())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/sale/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify({...this.state.table_filter, ...this.state.myfilters}), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/sale/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        await POST_API('/sale/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({
                _dados: res.data,
                table_column: [
                    { title: 'Cod.', dataIndex: 'ID', key: 'sale.ID', width: 80, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: 'Venda', dataIndex: 'DATE_SALE_FORMAT', width: 150, align: 'center', key: 'sale.DATE_SALE', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: 'Cliente', dataIndex: 'CLIENT_NAME', key: 'client.NAME', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                        <Row>
                            <Col span={24}>
                                <Tag color="#ff0000cc" title="Tipo">{record.INSTALLMENTS === '1' ? 'A Vista' : 'Parcelado '+record.INSTALLMENTS+'x' }</Tag>
                                <Tag color="#0000ff" title="Cliente">{record.CLIENT_NAME ? record.CLIENT_NAME : 'Sem cadastro'}</Tag>
                                <Typography>{record.NAME}</Typography>
                            </Col>
                        </Row>
                    ) },
                    { title: 'Valor a Receber', dataIndex: 'PRICE_FORMAT', width: 150, align: 'right', key: 'sale.PRICE', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: 'Vencimento', dataIndex: 'DATE_DUE_FORMAT', width: 150, align: 'center', key: 'sale.DATE_DUE', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: 'Ações', key: 'action', width: 150, render: (text, record) => (
                        <>
                            { this.props.type === 'list' ? (
                                <Row gutter={[4,4]} className="table__actions">
                                    <Col><Link to={verifyConfig(conf.edit) ? record.ID : '#'}><Button disabled={!verifyConfig(conf.edit)} className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col>
                                    <Col><Button disabled={!verifyConfig(conf.del)} onClick={() => this.onAction(record.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                                    <Col><Divider type="vertical"/></Col>
                                    <Col><Button onClick={() => this.onReport(record.ID)} className="table__btn table__btn_action_1" title='Comprovante' type="primary" shape="circle" size="small"><IoDownload size={14} /></Button></Col>
                                </Row>
                            ) : (
                                <Row gutter={[4,4]} className="table__actions">
                                    <Col><Button disabled={!verifyConfig(conf.rec)} onClick={() => this.onAction(record.ID, 'rec', 'Recuperar registro?')} className="table__btn table__btn_recover" title='Recuperar' type="primary" shape="circle" size='small'><IoReturnUpBackOutline size={14} /></Button></Col>
                                </Row>
                            ) }
                        </>
                    )},
                ]
            })
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    onReport = (ID) => {

        var mapForm = document.createElement("form");
        mapForm.target = "_blank";    
        mapForm.style = "display: none"
        mapForm.method = "POST";
        mapForm.action = `${URL_API}/reports/nf-sale.php`;

        var mapInput2 = document.createElement("input");
        mapInput2.type = "text";
        mapInput2.name = "token";
        mapInput2.value = getToken()
        mapForm.appendChild(mapInput2);

        var mapInput3 = document.createElement("input");
        mapInput3.type = "text";
        mapInput3.name = "master";
        mapInput3.value = JSON.stringify({ID: ID})
        mapForm.appendChild(mapInput3);

        document.body.appendChild(mapForm);
        mapForm.submit();

    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/sale">Vendas</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{ this.props.type === 'list' ? 'Lista' : 'Lixeira' }</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        { this.props.type === 'list' ? (
                            <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                { this.state.table_select.length > 0 ? <Button disabled={!verifyConfig(conf.del)} onClick={() => this.onAction(null, 'del-select', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Selecionados ({this.state.table_select.length})</Button> : null }
                                <Link to={verifyConfig(conf.trash) ? 'trash' : '#'}><Button onClick={this.onChangePage} disabled={!verifyConfig(conf.trash)} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Lixeira</Button></Link>
                                <Link to={verifyConfig(conf.add) ? 'add' : '#'}><Button disabled={!verifyConfig(conf.add)} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link>
                            </Col>
                        ) : (
                            <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                { this.state.table_select.length > 0 ? <Button disabled={!verifyConfig(conf.rec)} onClick={() => this.onAction(null, 'rec-select', 'Recuperar registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Recuperar Selecionados ({this.state.table_select.length})</Button> : null }
                                <Link to={'/dashboard/sale'}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                            </Col>
                        ) }
                        <Col span={24}>
                            <Card size="small" title="Filtros">
                                <Row align={'bottom'} gutter={[16, 16]}>
                                    <Col xs={24} md={5}>
                                        <Typography>Cliente</Typography>
                                        <Select onChange={(value) => this.setState({myfilters: { ...this.state.myfilters, CLIENT_ID: value }})} allowClear style={{width: '100%'}}>
                                            <Select.Option disabled>Selecione...</Select.Option>
                                            { this.state._dados?.FILTER_CLIENT.map((v, i) => (
                                                <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                            )) }
                                        </Select>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Typography>Venda (inicial)</Typography>
                                        <Input onChange={(value) => this.setState({myfilters: { ...this.state.myfilters, DATE_SALE_START: value.target.value }})}  type="date" />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Typography>Venda (final)</Typography>
                                        <Input onChange={(value) => this.setState({myfilters: { ...this.state.myfilters, DATE_SALE_END: value.target.value }})}  type="date" />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Typography>Vencimento (inicial)</Typography>
                                        <Input onChange={(value) => this.setState({myfilters: { ...this.state.myfilters, DATE_DUE_START: value.target.value }})}  type="date" />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Typography>Vencimento (final)</Typography>
                                        <Input onChange={(value) => this.setState({myfilters: { ...this.state.myfilters, DATE_DUE_END: value.target.value }})}  type="date" />
                                    </Col>
                                    <Col xs={24} md={3}>
                                        <Button block className="btn-primary" type="primary" onClick={this.onRenderData}>Filtrar</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Table
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                                _rowSelection={{onChange: this.onSelect, getCheckboxProps: () => {}}}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;