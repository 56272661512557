import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Select, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, getEnv, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import conf from './conf.json';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        defaultValue: null
    }

    componentDidMount = async () => {
        titleWeb('Usuários')
        await POST_API('/credential/filters.php', { token: getToken(), ACCOUNT_ID: (this.state._params[7] ) ? this.state._params[5] : null }).then(rs => rs.json()).then(res => {
            this.setState({_dados: res.data})
            if (this.props.type === 'add') {
                this.setState({_screen: false})
            } else if (this.props.type === 'edit') {
                this.onView()
            }
        })
    }

    onView = async () => {
        var control = { ID: (this.state._params[7] ) ? this.state._params[7] : this.state._params[5] }
        if (this.state._params[7] ) control.ACCOUNT_ID = this.state._params[5]
        POST_API('/credential/search.php', { token: getToken(), type: 'view', filter: JSON.stringify(control) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        if (this.state._params[7]) values.ACCOUNT_ID = this.state._params[5];
        POST_API('/credential/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate) {
            if (this.state._params[7] ) {
                return (<Navigate to={`/dashboard/account/${this.state._params[5]}/credential`} />)
            } else {
                return (<Navigate to={'/dashboard/credential'} />)
            }
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                { this.state._params[7]  ? (
                                    <Breadcrumb.Item><Link to="/dashboard/account">Clientes</Link></Breadcrumb.Item>
                                ) : null }
                                <Breadcrumb.Item><Link to={this.state._params[7] ? `/dashboard/account/${this.state._params[5]}/credential` : "/dashboard/credential" }>Usuários</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small" onClick={() => this.setState({_navigate: true})}>Voltar</Button>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`${getEnv()}-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Login" name="LOGIN" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={14} md={4}>
                                            <Form.Item label="Tipo Usuário" name="TYPE_CREDENTIAL_ID" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_TYPE_CREDENTIAL.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={10} md={4}>
                                            <Form.Item label="Status" name="STATUS" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_STATUS.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;