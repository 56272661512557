import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

// CONTROLLER
import Page404 from '../pages/Controller/Page404';

import Login from '../pages/Auth/Login';
import Dashboard from '../pages/App/Dashboard';
import DashboardMain from '../pages/App/Dashboard/Main';

// Perfil
import Perfil from '../pages/App/Perfil/Form';
// ENV
import AmbienteList from '../pages/App/Ambiente/List';
import AmbienteForm from '../pages/App/Ambiente/Form';
// PLANS
import PlanoList from '../pages/App/Plano/List';
import PlanoForm from '../pages/App/Plano/Form';
// PERMISSION
import PermissaoList from '../pages/App/Permissao/List';
import PermissaoForm from '../pages/App/Permissao/Form';
// TYPE CREDENTIAL
import TipoUsuarioList from '../pages/App/TipoUsuario/List';
import TipoUsuarioForm from '../pages/App/TipoUsuario/Form';
// CREDENTIAL
import UsuarioList from '../pages/App/Usuario/List';
import UsuarioForm from '../pages/App/Usuario/Form';
// ACCOUNT
import EmpresaList from '../pages/App/Empresa/List';
import EmpresaForm from '../pages/App/Empresa/Form';
// CLLIENT
import ClienteList from '../pages/App/Cliente/List';
import ClienteForm from '../pages/App/Cliente/Form';
// PROVIDER
import FornecedorList from '../pages/App/Fornecedor/List';
import FornecedorForm from '../pages/App/Fornecedor/Form';
// BRAND
import MarcaList from '../pages/App/Marca/List';
import MarcaForm from '../pages/App/Marca/Form';
// FOCUSNFE
import FocusNFeList from '../pages/App/FocusNFe/List';
import FocusNFeForm from '../pages/App/FocusNFe/Form';
// CATEGORY
import CategoriaList from '../pages/App/Categoria/List';
import CategoriaForm from '../pages/App/Categoria/Form';
// PRODUCT
import ProdutoList from '../pages/App/Produto/List';
import ProdutoForm from '../pages/App/Produto/Form';
import ProdutoVariacaoForm from '../pages/App/Produto/FormVariation';
import ProdutoGaleriaForm from '../pages/App/Produto/FormGallery';
// BILL TO PAY
import ContaPagarList from '../pages/App/ContaPagar/List';
import ContaPagarForm from '../pages/App/ContaPagar/Form';
// BILL TO RECEIVE
import ContaReceberList from '../pages/App/ContaReceber/List';
import ContaReceberForm from '../pages/App/ContaReceber/Form';
// EXPENSE
import DespesaList from '../pages/App/Despesa/List';
import DespesaForm from '../pages/App/Despesa/Form';
// BUY
import CompraList from '../pages/App/Compra/List';
import CompraForm from '../pages/App/Compra/Form';
// SALE
import VendaList from '../pages/App/Venda/List';
import VendaForm from '../pages/App/Venda/Form';
// CONDITIONAL
import CondicionalList from '../pages/App/Condicional/List';
import CondicionalForm from '../pages/App/Condicional/Form';
import CondicionalConfirm from '../pages/App/Condicional/Confirm';
// PAYMENT
import FormaPagamentoList from '../pages/App/FormaPagamento/List';
import FormaPagamentoForm from '../pages/App/FormaPagamento/Form';
// CHECKOUT
import CaixaList from '../pages/App/Caixa/List';
import CaixaForm from '../pages/App/Caixa/Form';

// CHECKOUT OPEN
import DashboardCheckout from '../pages/Checkout/Dashboard';
import CartCheckout from '../pages/Checkout/Cart';

// DOCUMENTOS FISCAIS 
import DocumentosFiscais from '../pages/App/DocumentosFiscais/List';
// NFe
import NFeList from '../pages/App/DocumentosFiscais/NFe/List';
import NFeForm from '../pages/App/DocumentosFiscais/NFe/Form';

class AppStack extends React.Component {

    render = () => {

        return (
            <BrowserRouter>
                <Routes>
                    <Route path="" element={<Login />} />
                    <Route path="checkout" element={<DashboardCheckout />}>
                        <Route path="" element={<CartCheckout />} />
                    </Route>
                    <Route path="dashboard" element={<Dashboard />}>
                        <Route path="" element={<DashboardMain />} />
                        <Route path="profile" element={<Perfil />} />
                        <Route path="fiscal" element={<Outlet />}>
                            <Route path="" element={<DocumentosFiscais />} />
                            <Route path="nfe" element={<Outlet />}>
                                <Route path="" element={<NFeList type="list" />} />
                                <Route path="add" element={<NFeForm type="add" />} />
                                <Route path=":id" element={<NFeForm type="edit" />} />
                            </Route>
                        </Route>
                        <Route path="account" element={<Outlet />}>
                            <Route path="" element={<EmpresaList type="list" />} />
                            <Route path="trash" element={<EmpresaList type="trash" />} />
                            <Route path="add" element={<EmpresaForm type="add" />} />
                            <Route path=":id" element={<Outlet />} >
                                <Route path="" element={<EmpresaForm type="edit" />} />
                                <Route path="typecredential" element={<Outlet />}>
                                    <Route path="" element={<TipoUsuarioList type="list" />} />
                                    <Route path="trash" element={<TipoUsuarioList type="trash" />} />
                                    <Route path="add" element={<TipoUsuarioForm type="add" />} />
                                    <Route path=":id" element={<TipoUsuarioForm type="edit" />} />
                                </Route>
                                <Route path="credential" element={<Outlet />}>
                                    <Route path="" element={<UsuarioList type="list" />} />
                                    <Route path="trash" element={<UsuarioList type="trash" />} />
                                    <Route path="add" element={<UsuarioForm type="add" />} />
                                    <Route path=":id" element={<UsuarioForm type="edit" />} />
                                </Route>
                                <Route path="payment" element={<Outlet />}>
                                    <Route path="" element={<FormaPagamentoList type="list" />} />
                                    <Route path="trash" element={<FormaPagamentoList type="trash" />} />
                                    <Route path="add" element={<FormaPagamentoForm type="add" />} />
                                    <Route path=":id" element={<FormaPagamentoForm type="edit" />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="product" element={<Outlet />}>
                            <Route path="" element={<ProdutoList type="list" />} />
                            <Route path="trash" element={<ProdutoList type="trash" />} />
                            <Route path="add" element={<ProdutoForm type="add" />} />
                            <Route path=":id" element={<ProdutoForm type="edit" />} />
                            <Route path=":id/add" element={<ProdutoVariacaoForm type="add" />} />
                            <Route path=":id/:id" element={<ProdutoVariacaoForm type="edit" />} />
                            <Route path=":id/:id/gallery" element={<ProdutoGaleriaForm />} />
                        </Route>
                        <Route path="checkout" element={<Outlet />}>
                            <Route path="" element={<CaixaList type="list" />} />
                            <Route path="trash" element={<CaixaList type="trash" />} />
                            <Route path="add" element={<CaixaForm type="add" />} />
                            <Route path=":id" element={<CaixaForm type="edit" />} />
                        </Route>
                        <Route path="category" element={<Outlet />}>
                            <Route path="" element={<CategoriaList type="list" />} />
                            <Route path="trash" element={<CategoriaList type="trash" />} />
                            <Route path="add" element={<CategoriaForm type="add" />} />
                            <Route path=":id" element={<CategoriaForm type="edit" />} />
                        </Route>
                        <Route path="expense" element={<Outlet />}>
                            <Route path="" element={<DespesaList type="list" />} />
                            <Route path="trash" element={<DespesaList type="trash" />} />
                            <Route path="add" element={<DespesaForm type="add" />} />
                            <Route path=":id" element={<DespesaForm type="edit" />} />
                        </Route>
                        <Route path="buy" element={<Outlet />}>
                            <Route path="" element={<CompraList type="list" />} />
                            <Route path="trash" element={<CompraList type="trash" />} />
                            <Route path="add" element={<CompraForm type="add" />} />
                            <Route path=":id" element={<CompraForm type="edit" />} />
                        </Route>
                        <Route path="sale" element={<Outlet />}>
                            <Route path="" element={<VendaList type="list" />} />
                            <Route path="trash" element={<VendaList type="trash" />} />
                            <Route path="add" element={<VendaForm type="add" />} />
                            <Route path=":id" element={<VendaForm type="edit" />} />
                        </Route>
                        <Route path="conditional" element={<Outlet />}>
                            <Route path="" element={<CondicionalList type="list" />} />
                            <Route path="trash" element={<CondicionalList type="trash" />} />
                            <Route path="add" element={<CondicionalForm type="add" />} />
                            <Route path=":id" element={<CondicionalForm type="edit" />} />
                            <Route path=":id/confirm" element={<CondicionalConfirm type="edit" />} />
                        </Route>
                        <Route path="billtopay" element={<Outlet />}>
                            <Route path="" element={<ContaPagarList type="list" />} />
                            <Route path=":id" element={<ContaPagarForm type="add" />} />
                            <Route path=":id/:id" element={<ContaPagarForm type="edit" />} />
                        </Route>
                        <Route path="billtoreceive" element={<Outlet />}>
                            <Route path="" element={<ContaReceberList type="list" />} />
                            <Route path=":id" element={<ContaReceberForm type="add" />} />
                            <Route path=":id/:id" element={<ContaReceberForm type="edit" />} />
                        </Route>
                        <Route path="brand" element={<Outlet />}>
                            <Route path="" element={<MarcaList type="list" />} />
                            <Route path="trash" element={<MarcaList type="trash" />} />
                            <Route path="add" element={<MarcaForm type="add" />} />
                            <Route path=":id" element={<MarcaForm type="edit" />} />
                        </Route>
                        <Route path="focusnfe" element={<Outlet />}>
                            <Route path="" element={<FocusNFeList type="list" />} />
                            <Route path="add" element={<FocusNFeForm type="add" />} />
                            <Route path=":id" element={<FocusNFeForm type="edit" />} />
                        </Route>
                        <Route path="client" element={<Outlet />}>
                            <Route path="" element={<ClienteList type="list" />} />
                            <Route path="trash" element={<ClienteList type="trash" />} />
                            <Route path="add" element={<ClienteForm type="add" />} />
                            <Route path=":id" element={<ClienteForm type="edit" />} />
                        </Route>
                        <Route path="provider" element={<Outlet />}>
                            <Route path="" element={<FornecedorList type="list" />} />
                            <Route path="trash" element={<FornecedorList type="trash" />} />
                            <Route path="add" element={<FornecedorForm type="add" />} />
                            <Route path=":id" element={<FornecedorForm type="edit" />} />
                        </Route>
                        <Route path="plan" element={<Outlet />}>
                            <Route path="" element={<PlanoList type="list" />} />
                            <Route path="trash" element={<PlanoList type="trash" />} />
                            <Route path="add" element={<PlanoForm type="add" />} />
                            <Route path=":id" element={<PlanoForm type="edit" />} />
                        </Route>
                        <Route path="environment" element={<Outlet />}>
                            <Route path="" element={<AmbienteList type="list" />} />
                            <Route path="trash" element={<AmbienteList type="trash" />} />
                            <Route path="add" element={<AmbienteForm type="add" />} />
                            <Route path=":id" element={<AmbienteForm type="edit" />} />
                        </Route>
                        <Route path="config" element={<Outlet />}>
                            <Route path="" element={<PermissaoList type="list" />} />
                            <Route path="trash" element={<PermissaoList type="trash" />} />
                            <Route path="add" element={<PermissaoForm type="add" />} />
                            <Route path=":id" element={<PermissaoForm type="edit" />} />
                        </Route>
                        <Route path="typecredential" element={<Outlet />}>
                            <Route path="" element={<TipoUsuarioList type="list" />} />
                            <Route path="trash" element={<TipoUsuarioList type="trash" />} />
                            <Route path="add" element={<TipoUsuarioForm type="add" />} />
                            <Route path=":id" element={<TipoUsuarioForm type="edit" />} />
                        </Route>
                        <Route path="credential" element={<Outlet />}>
                            <Route path="" element={<UsuarioList type="list" />} />
                            <Route path="trash" element={<UsuarioList type="trash" />} />
                            <Route path="add" element={<UsuarioForm type="add" />} />
                            <Route path=":id" element={<UsuarioForm type="edit" />} />
                        </Route>
                    <Route path="*" element={<Page404 />} />
                    </Route>
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        )

    }

}

export default AppStack;