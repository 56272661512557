import React from "react";
import { Button, Col, Layout, Row } from 'antd';
import { Link } from "react-router-dom";

import { IoAlbumsOutline, IoBookOutline, IoBuildOutline, IoChatboxOutline, IoCubeOutline, IoFolderOpenOutline, IoDesktopOutline, IoPeopleOutline, IoBookmarksOutline, IoBagOutline, IoApertureOutline, IoPersonOutline, IoColorFilterOutline, IoCashOutline, IoCartOutline, IoBarChartOutline, IoHappyOutline, IoPricetagOutline, IoBagCheckOutline, IoDocumentTextOutline } from 'react-icons/io5';
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi';
import { BsCashCoin } from "react-icons/bs";

import { getEnv, verifyConfig } from "../services/api";

class Screen extends React.Component {

    state = { select: 'dashboard', paths: [] }

    componentDidMount = () => {
        
        const params = window.location.href;
        const values = params.split('/');

        values[4] = values[4] ? values[4] : values[3]
        
        this.onSelect(values[4])

    }

    onSelect = (page) => {
        if (this.props?.clickOption) this.props?.clickOption()
        this.setState({select: page})
    }


    render = () => {

        const { Content } = Layout
        const menus = [
            { label: 'Planos', url: 'plan', value: 'plan', icon: <IoBookmarksOutline size={22}/>, permission: 'menu.plan' },
            { label: 'Ambientes', url: 'environment', value: 'environment', icon: <IoFolderOpenOutline size={22}/>, permission: 'menu.env' },
            { label: 'Empresas', url: 'account', value: 'account', icon: <IoDesktopOutline size={22}/>, permission: 'menu.account' },
            { label: 'Permissões', url: 'config', value: 'config', icon: <IoBuildOutline size={22}/>, permission: 'menu.config' },
            { label: 'FocusNFe', url: 'focusnfe', value: 'focusnfe', icon: <BsCashCoin size={22}/>, permission: 'menu.focusnfe' },
            { label: 'Tipos de Usuário', url: 'typecredential', value: 'typecredential', icon: <IoAlbumsOutline size={22}/>, permission: 'menu.type.user' },
            { label: 'Clientes', url: 'client', value: 'client', icon: <IoHappyOutline size={22}/>, permission: 'menu.client' },
            { label: 'Fornecedores', url: 'provider', value: 'provider', icon: <IoApertureOutline size={22}/>, permission: 'menu.provider' },
            { label: 'Produtos', url: 'product', value: 'product', icon: <IoBagOutline size={22}/>, permission: 'menu.product' },
            { label: 'Categorias', url: 'category', value: 'category', icon: <IoAlbumsOutline size={22}/>, permission: 'menu.category' },
            { label: 'Marcas', url: 'brand', value: 'brand', icon: <IoColorFilterOutline size={22}/>, permission: 'menu.brand' },
            { label: 'Caixas', url: 'checkout', value: 'checkout', icon: <IoBagCheckOutline size={22}/>, permission: 'menu.checkout' },
            { label: 'Contas a Receber', url: 'billtoreceive', value: 'billtoreceive', icon: <GiReceiveMoney size={22}/>, permission: 'menu.bill.receive' },
            { label: 'Vendas', url: 'sale', value: 'sale', icon: <IoBarChartOutline size={22}/>, permission: 'menu.sale' },
            { label: 'Condicional', url: 'conditional', value: 'conditional', icon: <IoPricetagOutline size={22}/>, permission: 'menu.conditional' },
            { label: 'Contas a Pagar', url: 'billtopay', value: 'billtopay', icon: <GiPayMoney size={22}/>, permission: 'menu.bill.pay' },
            { label: 'Despesas', url: 'expense', value: 'expense', icon: <IoCashOutline size={22}/>, permission: 'menu.expense' },
            { label: 'Compras', url: 'buy', value: 'buy', icon: <IoCartOutline size={22}/>, permission: 'menu.buy' },
            { label: 'Usuários', url: 'credential', value: 'credential', icon: <IoPeopleOutline size={22}/>, permission: 'menu.credential' },
            { label: 'Suporte', url: 'support', value: 'support', icon: <IoChatboxOutline size={22}/>, permission: 'menu.support' },
            { label: 'Logs', url: 'logs', value: 'logs', icon: <IoBookOutline size={22}/>, permission: 'menu.log' },
            { label: 'Documentos Fiscais', url: 'fiscal', value: 'fiscal', icon: <IoDocumentTextOutline size={22}/>, permission: 'menu.fiscal' },
        ]

        return (
            <Content style={{marginTop: 15}}>
                <Row gutter={[2,2]}>
                    <Col span={24} className={`${getEnv()}-sidebar-item`}>
                        <Link to={'/dashboard'}>
                            <Button onClick={() => this.onSelect('dashboard')} type="link" block className={this.state.select === 'dashboard' ? `${getEnv()}-sidebar-item-link active` : `${getEnv()}-sidebar-item-link`}>
                                <IoCubeOutline size={22}/> Dashboard 
                            </Button>
                        </Link>
                    </Col>   
                    <Col span={24} className={`${getEnv()}-sidebar-item`}>
                        <Link to={'profile'}>
                            <Button onClick={() => this.onSelect('profile')} type="link" block className={this.state.select === 'profile' ? `${getEnv()}-sidebar-item-link active` : `${getEnv()}-sidebar-item-link`}>
                                <IoPersonOutline size={22}/> Meu Perfil 
                            </Button>
                        </Link>
                    </Col>   
                    { menus.map((v, i) => {
                        if (verifyConfig(v.permission) || v.permission === true) {
                            return (
                                <Col key={i} span={24} className={`${getEnv()}-sidebar-item`}>
                                    <Link to={v.url}>
                                        <Button onClick={() => this.onSelect(v.value)} type="link" block className={this.state.select === v.value ? `${getEnv()}-sidebar-item-link active` : `${getEnv()}-sidebar-item-link`}>
                                            {v.icon} {v.label} 
                                        </Button>
                                    </Link>
                                </Col>  
                            )
                        }
                        return true
                    })}    
                </Row>
            </Content>
        )
    }

}

export default Screen;