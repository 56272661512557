import React from "react";
import { Breadcrumb, Button, Table, Card, Col, Input, Layout, Modal, Row, Select, Tag, Typography, message, Statistic } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoCash, IoPencil, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, URL_API, getEnv, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import TableComponent from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';

import conf from './conf.json';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _dados: null,
        myfilters: {
            STATUS: '',
            PROVIDER_ID: '',
            DATE_DUE_START: '',
            DATE_DUE_END: '',
        },
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
                current: 1,
                total: 0,
                limit: 10
            },
        table_filter: null,
        table_sorter: null,
        table_select: [],
    }

    componentDidMount = () => {
        titleWeb('Contas a Receber')

        this.onRenderColumns().then(() => this.onRenderData())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/bill_to_receive/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify({...this.state.table_filter, ...this.state.myfilters}), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        summary: res.summary,
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/bill_to_receive_payment/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    expandedRowRender = (row) => {

        var collums = [
            { title: 'Cod.', dataIndex: 'ID', width: 100, align: 'center', showSorterTooltip: false },
            { title: 'Valor Pago', align: "center", dataIndex: 'VALUE_FORMAT', showSorterTooltip: false },
            { title: 'Data Pagamento', align: "center", dataIndex: 'DATE_PAY_FORMAT', showSorterTooltip: false },
            { title: 'Ações', dataIndex: 'action', width: 100, align: 'center', showSorterTooltip: false, render: (text, record) => (
                <Row gutter={[4,4]} className="table__actions">
                    <Col><Link to={verifyConfig(conf.edit) ? row.ID+'/'+record.ID : '#'}><Button disabled={!verifyConfig(conf.edit)} className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col>
                    <Col><Button disabled={!verifyConfig(conf.del)} onClick={() => this.onAction(record.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                </Row>
            ) },
        ]

        var data = row.PAYMENTS

        return (
            <Table
                size="small"
                columns={collums}
                dataSource={data}
                // rowSelection={{onChange: this.onSelectVariation, getCheckboxProps: () => {}}}
                pagination={false}
                style={{marginBottom: 38}}
            />
        )
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        await POST_API('/bill_to_receive/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({
                _dados: res.data,
                table_column: [
                    { title: 'Cod.', dataIndex: 'ID', key: 'bill_to_receive.ID', width: 80, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: 'Nome', dataIndex: 'NAME', key: 'bill_to_receive.NAME', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                        <Row>
                            <Col span={24}>
                                <Tag color="#ff0000cc" title="Tipo">{record.TYPE_TEXT}</Tag>
                                <Tag color="#0000ff" title="Fornecedor">{record.CLIENT_NAME ? record.CLIENT_NAME : 'Sem cadastro'}</Tag>
                                <Typography>{record.NAME}</Typography>
                            </Col>
                        </Row>
                    ) },
                    { title: 'Valor', dataIndex: 'PRICE', width: 150, align: 'center', key: 'bill_to_receive.PRICE', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                        <Row>
                            <Col span={24}>
                                <Typography title="Valor à pagar" style={{color: 'red', fontSize: 12}}>{record.PRICE_FORMAT}</Typography>
                                <Typography title="Valor pago" style={{color: 'green', fontSize: 12}}>{record.PAID_FORMAT}</Typography>
                                <Typography title="Percentual" style={{fontSize: 12}}><b>({record.PERCENT})</b></Typography>
                            </Col>
                        </Row>
                    ) },
                    { title: 'Vencimento', dataIndex: 'DATE_DUE_FORMAT', width: 150, align: 'center', key: 'bill_to_receive.DATE_DUE', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                    { title: 'Ações', key: 'action', width: 100, render: (text, record) => (
                        <>
                            { this.props.type === 'list' ? (
                                <Row gutter={[4,4]} className="table__actions">
                                    <Col><Link to={(Number(record.PRICE) > Number(record.PAID)) ? record.ID : '#'}><Button disabled={(Number(record.PRICE) <= Number(record.PAID))} className="table__btn table__btn_success" title='Adicionar Pagamento' type="primary" shape="circle" size="small"><IoCash size={14} /></Button></Link></Col>
                                </Row>
                            ) : null }
                        </>
                    )},
                ]
            })
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    openReport = () => {

        var mapForm = document.createElement("form");
        mapForm.target = "_blank";    
        mapForm.style = "display: none"
        mapForm.method = "POST";
        mapForm.action = `${URL_API}/reports/billtoreceive.php`;

        var mapInput2 = document.createElement("input");
        mapInput2.type = "text";
        mapInput2.name = "token";
        mapInput2.value = getToken()
        mapForm.appendChild(mapInput2);

        var mapInput3 = document.createElement("input");
        mapInput3.type = "text";
        mapInput3.name = "master";
        mapInput3.value = JSON.stringify(this.state.myfilters)
        mapForm.appendChild(mapInput3);

        var mapInput4 = document.createElement("input");
        mapInput4.type = "text";
        mapInput4.name = "sorter";
        mapInput4.value = JSON.stringify(this.state.table_sorter)
        mapForm.appendChild(mapInput4);

        document.body.appendChild(mapForm);
        mapForm.submit();

    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/billtoreceive">Contas a Receber</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{ this.props.type === 'list' ? 'Lista' : 'Lixeira' }</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={this.openReport} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Gerar Relatório</Button>
                        </Col>
                        <Col xs={24} md={8}>
                            <Card bordered={false}>
                                <Statistic
                                    loading={this.state.table_load}
                                    title="A Receber"
                                    value={this.state.summary?.PRICE_FORMAT}
                                    valueStyle={{ color: '#860000' }}
                                    prefix={'R$'}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={8}>
                            <Card bordered={false}>
                                <Statistic
                                    loading={this.state.table_load}
                                    title="Recebido"
                                    value={this.state.summary?.PAID_FORMAT}
                                    valueStyle={{ color: '#008600' }}
                                    prefix={'R$'}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={8}>
                            <Card bordered={false}>
                                <Statistic
                                    loading={this.state.table_load}
                                    title="Percentual"
                                    value={this.state.summary?.PERCENT}
                                    valueStyle={{ color: '#000000' }}
                                    suffix={'%'}
                                />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card bordered={false} size="small" title="Filtros">
                                <Row align={'bottom'} gutter={[16, 16]}>
                                    <Col xs={24} md={4}>
                                        <Typography>Status</Typography>
                                        <Select onChange={(value) => this.setState({myfilters: { ...this.state.myfilters, STATUS: value }})} allowClear style={{width: '100%'}}>
                                            <Select.Option disabled>Selecione...</Select.Option>
                                            { this.state._dados?.FILTER_STATUS.map((v, i) => (
                                                <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                            )) }
                                        </Select>
                                    </Col>
                                    <Col xs={24} md={9}>
                                        <Typography>Cliente</Typography>
                                        <Select onChange={(value) => this.setState({myfilters: { ...this.state.myfilters, CLIENT_ID: value }})} allowClear style={{width: '100%'}}>
                                            <Select.Option disabled>Selecione...</Select.Option>
                                            { this.state._dados?.FILTER_CLIENT.map((v, i) => (
                                                <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                            )) }
                                        </Select>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Typography>Vencimento (inicial)</Typography>
                                        <Input onChange={(value) => this.setState({myfilters: { ...this.state.myfilters, DATE_DUE_START: value.target.value }})}  type="date" />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Typography>Vencimento (final)</Typography>
                                        <Input onChange={(value) => this.setState({myfilters: { ...this.state.myfilters, DATE_DUE_END: value.target.value }})}  type="date" />
                                    </Col>
                                    <Col xs={24} md={3}>
                                        <Button block className="btn-primary" type="primary" onClick={this.onRenderData}>Filtrar</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <TableComponent
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                                _expandedRows={(this.props.type === 'list') ? { expandedRowRender: this.expandedRowRender, defaultExpandedRowKeys: null } : null}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;