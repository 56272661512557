import React from "react";
import { Avatar, Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, Row, Tag, Typography, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, getEnv, getToken, getUPLOADAPI, titleWeb } from "../../../services/api";

// CONTROLLER
import PageLoad from '../../Controller/PageLoad';

import { IoCameraOutline } from 'react-icons/io5';
import { setPath } from "../../../services/api";
import FormItem from "antd/es/form/FormItem";
import ReactInputMask from "react-input-mask";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        defaultValue: null
    }

    componentDidMount = () => {
        titleWeb('Meu Perfil')
        setPath('credential')
        this.onViewAccount()
        this.onView()
    }

    buscaCEP = async (val) => {
        var cep = String(val.target.value).replace('-', '')
        this.setState({_screen: true})
        await fetch('https://viacep.com.br/ws/'+cep+'/json/').then(rs => rs.json()).then(res => {
            if (res.erro) {
                message.warning('CEP inválido')
            } else {
                this.setState({
                    defaultValueAccount: {
                        ...this.state.defaultValueAccount,
                        STREET: res.logradouro,
                        DISTRICT: res.bairro,
                        CITY: res.localidade,
                        STATE: res.uf,
                        CEP: cep
                    }
                })
            }
        }).catch(() => {
            message.warning('Não foi possível estabelecer uma conexão com o sistema')
        }).finally(() => this.setState({_screen: false}))

    }

    onView = async () => {
        await POST_API('/credential/search.php', { token: getToken(), type: 'self' }).then(rs => rs.json()).then(res => {
            this.setState({defaultValue: res.data, _screen: false})
        })
    }

    onViewAccount = async () => {
        await POST_API('/account/search.php', { token: getToken(), type: 'self' }).then(rs => rs.json()).then(res => {
            this.setState({defaultValueAccount: res.data})
        })
    }

    onSend = (values) => {
        this.setState({_screen: true})
        POST_API('/credential/self-pass.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSendAccount = (values) => {
        this.setState({_loading: true})
        POST_API('/account/self-save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onChangePic = (value) => {
        if (value.file.response?.url) {
            this.setState({_screen: true})
            POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({AVATAR: value.file.response?.url}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    onChangeName = (value) => {
        this.setState({_screen: true})
        POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({NAME: value}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView())
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/category'} />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/profile">Meu Perfil</Link></Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <>
                            
                                <Col xs={24} md={8}>
                                    <Card bordered size="small" style={{paddingTop: 20}}>
                                        <Row justify="center" align={'middle'} style={{flexDirection:  'column'}}>
                                            <Avatar size={200} shape="circle" src={this.state.defaultValue.AVATAR} style={{marginBottom: 12}}/>
                                            <Upload maxCount={1} showUploadList={false} accept="image/jpg,image/jpeg,image/png" action={getUPLOADAPI} onChange={this.onChangePic} className={`${getEnv()}-profile-upload`}>
                                                <Button shape="circle" className="btn-nav" type="default"><IoCameraOutline size={20} /></Button>
                                            </Upload>
                                            <Typography.Paragraph editable={{ tooltip: false, onChange: this.onChangeName }} className={`${getEnv()}-profile-name`}>{this.state.defaultValue.NAME}</Typography.Paragraph>
                                            <Tag color={this.state.defaultValue.STATUS_COLOR}>{this.state.defaultValue.TYPE_CREDENTIAL_NAME}</Tag>
                                            <Divider />
                                            <Form onFinish={this.onSend}>
                                                <FormItem label="Senha atual" name="OLD_PASSWORD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                    <Input type="password"/>
                                                </FormItem>
                                                <FormItem label="Nova senha" name="NEW_PASSWORD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                    <Input type="password"/>
                                                </FormItem>
                                                <FormItem>
                                                    <Button shape="round" htmlType="submit" className="btn-primary" block type="primary">Alterar Senha</Button>
                                                </FormItem>
                                            </Form>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={24} md={16}>
                                    <Card title="Dados da empresa" bordered size="small">
                                        <Form layout="vertical" onFinish={this.onSendAccount} initialValues={this.state.defaultValueAccount}>
                                            <Form.Item hidden name="ID">
                                                <Input />
                                            </Form.Item>
                                            <Row gutter={[16,0]}>
                                                <Col xs={24} md={16}>
                                                    <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                        <Input type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={8}>
                                                    <Form.Item label="E-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                        <Input type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={6}>
                                                    <Form.Item label="CNPJ" name="CPFCNPJ" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                        <ReactInputMask mask={'99.999.999/9999-99'} className="ant-input css-dev-only-do-not-override-zjzpde css-zjzpde" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={6}>
                                                    <Form.Item label="Telefone" name="PHONE">
                                                        <Input type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Item label="Cor Primária" name="COLOR_PRIMARY" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                        <Input type='color'  />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Item label="Cor Secundária" name="COLOR_SECONDARY" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                        <Input type='color'  />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={5}>
                                                    <Form.Item label="CEP" name="CEP">
                                                        <ReactInputMask mask={'99999-999'} className="ant-input css-dev-only-do-not-override-zjzpde css-zjzpde" onBlur={this.buscaCEP} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={15}>
                                                    <Form.Item label="Endereço" name="STREET">
                                                        <Input type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={4}>
                                                    <Form.Item label="Número" name="NUMBER">
                                                        <Input type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={10}>
                                                    <Form.Item label="Bairro" name="DISTRICT">
                                                        <Input type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={10}>
                                                    <Form.Item label="Cidade" name="CITY">
                                                        <Input type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={4}>
                                                    <Form.Item label="UF" name="STATE">
                                                        <Input type='text' maxLength={2} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item label="Logo" name="LOGO">
                                                        <Upload maxCount={1} action={getUPLOADAPI} accept="image/png,image/jpg,image/jpeg">
                                                            <Button block>Selecionar imagem</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item label="Favicon" name="FAVICON">
                                                        <Upload maxCount={1} action={getUPLOADAPI} accept="image/png,image/jpg,image/jpeg">
                                                            <Button block>Selecionar imagem</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card>
                                </Col>
                            </>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;