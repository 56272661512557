import React from "react";

// ROTAS ADMINISTRACAO
import AppStackAdmin from './AppStack.Admin';

class AppStack extends React.Component {

    render = () => {

        return <AppStackAdmin />

    }

}

export default AppStack;