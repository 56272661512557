import React from "react";
import { Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, Row, Select, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { BLOCK_FORM_ENTER, POST_API, getEnv, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import conf from './conf.json';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _lista: [],
        _select: [],
        _screen: true,
        _dados: null,
        _search: false,
        valorbruto: 0,
        valorfinal: 0,
        valorcredito: 0,
        valordesconto: 0,
        valortaxa: 0,
        aplicartaxa: 0,
        total: 0,
        _params: window.location.href.split('/'),
        defaultValue: null
    }

    componentDidMount = async () => {
        titleWeb('Vendas')
        await POST_API('/sale/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({_dados: res.data})
            if (this.props.type === 'add') {
                this.setState({_screen: false})
            } else if (this.props.type === 'edit') {
                this.onView()
            }
        })
    }

    onView = async () => {
        POST_API('/sale/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    defaultValue: res.data,
                    _select: res.data.PRODUTOS,
                    valorbruto: res.data.TOTAL,
                    valorfinal: res.data.PRICE,
                    valorcredito: res.data.CREDIT,
                    valordesconto: res.data.DISCOUNT,
                    valortaxa: res.data.PAYMENT_TAX,
                    aplicartaxa: res.data.APPLY_TAX,
                })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        values.TOTAL = this.state.valorbruto
        values.PRICE = this.state.valorfinal
        POST_API('/sale/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onSearch = (search) => {
        this.setState({_search: true})
        POST_API('/product_variation/search.php', { token: getToken(), type: 'list', pagination: JSON.stringify({current: 0, limit: 10}), search: search.target.value }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_lista: res.data});
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_search: false}))
    }

    onAddProd = (v) => {
        this.setState({
            _select: [ ...this.state._select, v ],
            _lista: []
        })
    }

    onRemoveProd = (v) => {
        var temp = this.state._select;
        temp.splice(v, 1)
        this.setState({_select: temp})
        this.onBlurQTY()
    }

    onChangeQTY = (v, i) => {
        var temp = this.state._select;
        temp[i].QTY = v.target.value
        this.setState({_select: temp})
    }

    onBlurQTY = () => {
        var value = 0;
        var final = 0;
        var temp = this.state._select;
        temp.map((item) => {
            value += (item.QTY * item.PRICE_SALE)
            return 1
        })

        var tax = (this.state.aplicartaxa === '1') ? value*this.state.valortaxa/100 : 0
        var desconto = value*this.state.valordesconto/100
        final = value - this.state.valorcredito - desconto + tax;

        this.setState({valorbruto: value, valorfinal: final, _loading: false})
    }

    onChangeCredito = (v) => {
        this.setState({valorcredito: v.target.value})
    }

    onChangeDesconto = (v) => {
        this.setState({valordesconto: v.target.value})
    }

    onChangeTaxa = (v) => {
        this.setState({valortaxa: v})
    }

    onChangeAplicarTaxa = (v) => {
        this.setState({aplicartaxa: v})
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/sale'} />)
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/sale">Vendas</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={'/dashboard/sale'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form onKeyDown={BLOCK_FORM_ENTER} layout="vertical" className={`${getEnv()}-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Cliente" name="CLIENT_ID" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled value="0">Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_CLIENT.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Item label="Data Venda" name="DATE_SALE" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type="date" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Item label="Data Vencimento" name="DATE_DUE" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type="date" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Market Place" name="MARKET_PLACE" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled value="0">Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_MARKET_PLACE.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={7}>
                                            <Form.Item label="Forma de Pagamento" name="PAYMENT_ID" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear onChange={(a, b) => this.onChangeTaxa(b['data-tax'])} onBlur={this.onBlurQTY}>
                                                    <Select.Option disabled value="0">Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_PAYMENT.map((v, i) => (
                                                        <Select.Option data-tax={v.TAX} key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item label="Aplicar Juros" name="APPLY_TAX" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear onChange={(v) => this.onChangeAplicarTaxa(v)} onBlur={this.onBlurQTY}>
                                                    <Select.Option disabled value="0">Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_APPLY_TAX.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Item label="Crédito" name="CREDIT">
                                                <Input addonBefore="R$" type="number" onChange={this.onChangeCredito} onBlur={this.onBlurQTY} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Item label="Desconto" name="DISCOUNT">
                                                <Input addonAfter="%" type="number" onChange={this.onChangeDesconto} onBlur={this.onBlurQTY} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Typography style={{marginBottom: 8}}>Valor Bruto</Typography>
                                            <Input value={this.state.valorbruto} addonBefore="R$" type="number" readOnly />
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Typography style={{marginBottom: 8}}>Valor Final</Typography>
                                            <Input value={this.state.valorfinal} addonBefore="R$" type="number" readOnly />
                                        </Col>
                                        <Col span={24}><Divider>Produtos</Divider></Col>
                                        { this.state._select.map((v, i) => (
                                            <Col key={i} md={24} xs={24} style={{marginBottom: 16}}>
                                                <Card hoverable size="small" title={'#'+v.BARCODE}>
                                                    <Row gutter={[8,0]} align={'bottom'}>
                                                        <Col xs={24} md={8}>
                                                            <Typography style={{marginBottom: 8}}>Nome</Typography>
                                                            <Input value={v.PRODUCT_NAME} readOnly style={{marginBottom: 24}} />
                                                        </Col>
                                                        <Col xs={12} md={3}>
                                                            <Typography style={{marginBottom: 8}}>Cor</Typography>
                                                            <Input value={v.COLOR} readOnly style={{marginBottom: 24}} />
                                                        </Col>
                                                        <Col xs={12} md={3}>
                                                            <Typography style={{marginBottom: 8}}>Tamanho</Typography>
                                                            <Input value={v.SIZE} readOnly style={{marginBottom: 24}} />
                                                        </Col>
                                                        <Col xs={14} md={3}>
                                                            <Typography style={{marginBottom: 8}}>Valor</Typography>
                                                            <Input addonBefore="R$" value={v.PRICE_SALE} readOnly style={{marginBottom: 24}} />
                                                        </Col>
                                                        <Col xs={10} md={4}>
                                                            <Form.Item label="Quantidade" name={`PRODUCT_${v.ID}_QTD`} rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                                <Input type="number" onChange={(val) => this.onChangeQTY(val, i)} onBlur={this.onBlurQTY} onFocus={() => this.setState({_loading: true})} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={3}>
                                                            <Button onClick={() => this.onRemoveProd(i)} block type="primary" className="table__btn_delete" style={{marginBottom: 24}}>Deletar</Button>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        ))}
                                        <Col xs={24} md={24}>
                                            <Input allowClear onChange={this.onSearch} style={{marginBottom: 25}} placeholder="Pesquisar produtos por nome, categoria, cor, tamanho, material, ID ou código de barras." />
                                        </Col>
                                        {this.state._lista.map((v, i) => (
                                            <Col key={i} md={24} xs={24} style={{marginBottom: 16}}>
                                                <Card onClick={() => this.onAddProd(v)} hoverable size="small" title={'#'+v.BARCODE}>
                                                    <Row gutter={[8,8]}>
                                                        <Col xs={24} md={12}>
                                                            <Typography>Nome</Typography>
                                                            <Input value={v.PRODUCT_NAME} readOnly />
                                                        </Col>
                                                        <Col xs={24} md={6}>
                                                            <Typography>Categoria</Typography>
                                                            <Input value={v.CATEGORY_NAME} readOnly />
                                                        </Col>
                                                        <Col xs={12} md={3}>
                                                            <Typography>Cor</Typography>
                                                            <Input value={v.COLOR} readOnly />
                                                        </Col>
                                                        <Col xs={12} md={3}>
                                                            <Typography>Tamanho</Typography>
                                                            <Input value={v.SIZE} readOnly />
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        ))}
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;