import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Select, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, getEnv, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import conf from './conf.json';
import ReactInputMask from "react-input-mask";
import { IoLogoFacebook, IoLogoInstagram, IoLogoTwitter } from "react-icons/io5";


class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        defaultValue: null
    }

    componentDidMount = async () => {
        titleWeb('Clientes')
        await POST_API('/client/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            this.setState({_dados: res.data})
            if (this.props.type === 'add') {
                this.setState({_screen: false})
            } else if (this.props.type === 'edit') {
                this.onView()
            }
        })
    }

    onView = async () => {
        POST_API('/client/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/client/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    buscaCEP = async (val) => {
        var cep = String(val.target.value).replace('-', '')
        this.setState({_screen: true})
        await fetch('https://viacep.com.br/ws/'+cep+'/json/').then(rs => rs.json()).then(res => {
            if (res.erro) {
                message.warning('CEP inválido')
            } else {
                this.setState({
                    defaultValue: {
                        ...this.state.defaultValue,
                        STREET: res.logradouro,
                        DISTRICT: res.bairro,
                        CITY: res.localidade,
                        STATE: res.uf
                    }
                })
            }
        }).catch(() => {
            message.warning('Não foi possível estabelecer uma conexão com o sistema')
        }).finally(() => this.setState({_screen: false}))

    }

    onChangeField = (a, b) => {
        var name = a[0].name[0]
        var value = a[0].value
        this.setState({
            defaultValue: {
                ...this.state.defaultValue,
                [name]: value
            }
        })
    } 

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/client'} />)
        }

        if (getToken() == null || !verifyConfig(conf.list)) {
            return (<Page403 />)
        }

        return (
            <Layout>
                <Layout.Content className={`${getEnv()}-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/dashboard/client">Clientes</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={'/dashboard/client'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`${getEnv()}-form`} onFinish={this.onSend} initialValues={this.state.defaultValue} onFieldsChange={(a, b) => this.onChangeField(a, b)}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={11}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="CPF" name="CPF" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <ReactInputMask mask={'999.999.999-99'} className="ant-input css-dev-only-do-not-override-zjzpde css-zjzpde" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item label="RG" name="RG">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item label="Celular" name="PHONE">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item label="Status" name="STATUS" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear>
                                                    <Select.Option disabled>Selecione...</Select.Option>
                                                    { this.state._dados.FILTER_STATUS.map((v, i) => (
                                                        <Select.Option key={i} value={v.value}>{v.text}</Select.Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Form.Item label="E-mail" name="EMAIL">
                                                <Input type='email' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Form.Item label="Instagram" name="INSTAGRAM">
                                                <Input addonBefore={<IoLogoInstagram />} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Form.Item label="Facebook" name="FACEBOOK">
                                                <Input addonBefore={<IoLogoFacebook />} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={5}>
                                            <Form.Item label="Twitter" name="TWITTER">
                                                <Input addonBefore={<IoLogoTwitter />} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Data Nascimento" name="BIRTH_DATE">
                                                <Input type='date' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item label="CEP" name="CEP">
                                                <ReactInputMask mask={'99999-999'} className="ant-input css-dev-only-do-not-override-zjzpde css-zjzpde" onBlur={this.buscaCEP} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Endereço" name="STREET">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item label="Número" name="NUMBER">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Bairro" name="DISTRICT">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Cidade" name="CITY">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={2}>
                                            <Form.Item label="UF" name="STATE">
                                                <Input type='text' maxLength={2} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default Screen;